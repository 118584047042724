import React, { useEffect, useState } from "react";

import "../../../Styles/SellpageStyle/mainSell.css";
import "../../../assets/Css/PropExperience.css";

import Slider from "react-slick";
import { axiosbaseurl } from "../../../config";
import CustomLoader from "../../CustomLoader";
import StarterForm from "./StarterForm";

const PropreneurExperience = ({homePageData}) => {

    console.log(homePageData)

    const [activeAccordian, setActiveAccordian] = useState(0);
    const [activeAcc, setActiveAcc] = useState(1);
    const [propData, setPropData] = useState({});
    const [loading, setLoading] = useState(false);
    const [mobileImgAccordian, setMobileImgAccordian] = useState(0);

    useEffect(() => {

        const controller = new AbortController();
        async function getPropExpData() {
            try {
                setLoading(true);
                const { data } = await axiosbaseurl.get(`/ExperienceData`);

                if (data.success == true) {
                    setPropData(data.data);
                    // console.log(data.data);
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
        }

        getPropExpData();

        return () => {
            controller.abort();
        };
    }, []);

    const sliderSettings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        swipeToSlide: true,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 8000,
        mobileFirst: true,
        dots: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 985,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    };


    const {
        first, second, third, fourth, fifth, sixth, seventh
    } = propData

    return (
        <div>

            {loading && <CustomLoader />}
            {/* first */}
            <section className="bg-yellow mt-90">
                <div className="prop-sold-box row align-items-center justify-content-between auto-container-lg">
                    <div className="col-md-5">
                        <h2 className="mb-4 pb-2">{first?.title}</h2>
                        <p className="text-dark-blue">{first?.subtitle}</p>
                    </div>
                    <div className="col-md-7">
                        <img src={first?.img} className="w-100" alt="" />
                    </div>
                </div>
            </section>

            {/* connect form */}
            <section className="py-5">
                <div className="pt-md-5">

                    <div className="prop-top-heading px-4 pb-5">
                        {/* <h3 className="mb-4">Not sure where to start?</h3>
                        <p className="font-saira mb-0">Let us reach out to you for understanding you service requirements</p> */}
                        <h3 className="mb-4">{homePageData?.titles?.[homePageData?.titles?.length - 1]?.title}</h3>
                        <p className="font-saira mb-0">{homePageData?.titles?.[homePageData?.titles?.length - 1]?.subtitle}</p>
                    </div>
                    <StarterForm pageName={"propreneur experience"} />
                </div>
            </section>

            {/* Propusers Assured */}
            <section className="py-5">
                <div className="prop-top-heading px-4">
                    <h3 className="mb-4">{second?.title}</h3>
                </div>

                <div className="auto-container-lg">
                    <div className="row">
                        {
                            second?.features?.map((feature, index) => {
                                return (
                                    (index % 2 === 0) ?

                                        <div className="col-md-6 mt-4 pt-3" key={feature.id}>
                                            <div className="row prop-assured align-items-center flex-md-row flex-column-reverse">
                                                <div className="col-md-6 pe-md-5 text-md-end">
                                                    <h4 className="mt-3">{feature?.title}</h4>
                                                    <div className="line-yellow ms-md-auto"></div>
                                                    <p className="mt-4"
                                                        dangerouslySetInnerHTML={{ __html: feature?.subtitle }}
                                                    />
                                                </div>
                                                <div className="col-md-6 ps-md-0 pe-3">
                                                    <img src={feature?.image} alt="" className="w-100" />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-md-6 mt-4 pt-3" key={feature.id}>
                                            <div className="row prop-assured align-items-center">

                                                <div className="col-md-6 pe-md-0 ps-3">
                                                    <img src={feature?.image} alt="" className="w-100" />
                                                </div>
                                                <div className="col-md-6 ps-md-5">
                                                    <h4 className="mt-3">{feature?.title}</h4>
                                                    <div className="line-yellow"></div>
                                                    <p className="mt-4"
                                                        dangerouslySetInnerHTML={{ __html: feature?.subtitle }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

            {/* Selling Services */}
            <section className="py-5">

                <div className="pt-md-5 auto-container-lg">
                    <div className="prop-top-heading align-items-start">
                        <h3 className="mb-4">{third?.title}</h3>
                    </div>
                    <div className="mt-4">
                        <Slider {...sliderSettings} className="pb-4">
                            {third?.features?.map((benefit, i) => {
                                return <BenefitCard key={i} benefit={benefit} />;
                            })}
                        </Slider>
                    </div>
                </div>
            </section>

            {/* Selling Process */}
            <section className="py-5">
                <div className="auto-container-lg">
                    <div className="prop-top-heading align-items-start mb-4">
                        <h3 className="mb-4">{fourth?.title}</h3>
                    </div>

                    {/* for desktop view */}
                    <div className="appFrame d-md-block d-none">
                        <article className="accordion w100" style={{ height: "100%" }}>
                            {
                                fourth?.features?.sort((a, b) => (a.sequence - b.sequence))?.slice(0, 5)?.map((service, index) => {
                                    return (
                                        <section key={service.id} onClick={() => setActiveAcc(index + 1)} id={`acc${index}`} className={`d-flex justify-content-end flex-column ${activeAcc == (index + 1) && 'active-hori-accordian'}`}
                                            style={{
                                                background: (activeAcc == (index + 1)) ?
                                                    `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${service?.image})` : '#1764a8'
                                            }}
                                        >
                                            <h2 ><a ><u className="size-under-line">{service?.title?.slice(0, 5)}</u>{service?.title?.slice(5)} </a></h2>
                                            <a id="bd-num" className={`num-bd${index}`}>{index + 1}</a>

                                            <div className="acc-content">
                                                <div className="selling-service-card" >
                                                    <h3 className="text-white">{service?.title}</h3>
                                                    <div className="line-yellow"></div>
                                                    <div className="mt-4">
                                                        <p className="text-yellow mb-0"
                                                            dangerouslySetInnerHTML={{ __html: service?.subtitle }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    )
                                })
                            }
                        </article>
                    </div>

                    {/* for mobile view */}
                    <div className=" d-md-none d-block">
                        {
                            fourth?.features?.map((service, index) => {
                                return (
                                    <div key={service.id} className="selling_img_accordian"
                                        style={{
                                            backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${service?.image})`,
                                            backgroundSize: 'cover'
                                        }}
                                    >
                                        <div id={`${mobileImgAccordian !== index && `acc${index}`}`}
                                            className="p-3"
                                            onClick={() => setMobileImgAccordian(index)}
                                        >
                                            <h6 className="text-white">{index + 1} {service?.title}</h6>
                                            <div className="line-yellow"></div>
                                        </div>
                                        <div className={`p-3 ${(mobileImgAccordian === index) ? 'd-block' : 'd-none'}`}>
                                            <div className="selling-service-card" >

                                                <div className="my-4">
                                                    <p className="text-yellow mb-0"
                                                        dangerouslySetInnerHTML={{ __html: service?.subtitle }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

            {/* Property Makeover */}
            <section className="py-5">
                <div className="ps-md-5 ms-md-5">
                    <div className="row mx-0 justify-content-md-between justify-content-center align-items-center ">
                        <div className="col-md-5 col-11">
                            <div className="prop-top-heading align-items-start">
                                <h3 className="mb-4">{fifth?.title}</h3>
                            </div>
                            <p className="paragraph lh-lg"
                                dangerouslySetInnerHTML={{ __html: fifth?.subtitle }}
                            />

                        </div>
                        <div className="col-md-6 col-11 pe-md-0 ">
                            <img src={fifth?.img} alt="" className="w-100 property-makeover-img" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Makeover Services Stack */}
            <section className="py-5">
                <div className="pe-md-5 pb-md-5">
                    <div className="row mx-0 justify-content-md-between justify-content-center align-items-center flex-md-row flex-column-reverse ">
                        <div className="col-md-4 col-11 ps-md-0 mt-md-0 mt-4">
                            <img src={sixth?.img} alt="" className="w-100 property-makeover-img" />
                        </div>
                        <div className="col-md-8 col-11 ps-md-4">
                            <div className="prop-top-heading align-items-start">
                                <h3 className="mb-4">{sixth?.title}</h3>
                            </div>
                            <div className="row ps-md-4">
                                {
                                    sixth?.subtitle?.split(',')?.map((point, index) => {
                                        return (
                                            <div className="col-md-4" key={index}>
                                                <div className="service-stack-list">
                                                    <div className="ps-2 mt-4">{point}</div>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* Frequently Asked Questions */}
            <section className="py-5 mb-90"
                style={{
                    backgroundImage: `url('${seventh?.img}')`,
                    backgroundSize: "cover",
                }}
            >
                <div className="py-md-5">
                    <div className="row  align-items-center justify-content-md-end justify-content-center mx-0">

                        <div className="col-md-8 col-11 pe-md-0 ps-md-5">
                            <div className="right-white-section w-100 px-0">
                                <div className="prop-top-heading align-items-start ps-sm-5 ps-4">
                                    <h3 className="mb-4">{seventh?.title}</h3>
                                </div>
                                {seventh?.features?.map((accordian, index) => {
                                    return (
                                        <CusromAccordian
                                            key={index}
                                            index={index}
                                            acc_data={accordian}
                                            activeAccordian={activeAccordian}
                                            setActiveAccordian={setActiveAccordian}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

const BenefitCard = ({ benefit }) => {
    return (
        <div
            className="makeover-benefit-card d-flex align-items-end me-md-5"
            style={{
                backgroundImage: `linear-gradient(#111111a0, #111111a0),url(${benefit.image})`,
            }}
        >
            <div className="benefit-card-content selling-service-card" >
                <h3 className="text-white">{benefit?.title}</h3>
                <div className="line-yellow"></div>
                <div className="mt-4">
                    <p className="text-white mb-4"
                        style={{
                            height: '150px',
                            overflow: 'auto',
                            scrollbarWidth: 'thin'
                        }}
                        dangerouslySetInnerHTML={{ __html: benefit?.subtitle }}
                    />
                </div>
            </div>
        </div>
    );
};

const CusromAccordian = ({
    acc_data,
    index,
    activeAccordian,
    setActiveAccordian,
}) => {
    // console.log(index)
    const isActve = activeAccordian === index;
    return (
        <div className={`custom-accordian ps-sm-5 ps-4`}>
            <div className="d-flex justify-content-between">
                <div
                    className="text-dark-blue fw-600 paragraph pe-3"
                    style={{ flex: 1 }}
                >
                    {acc_data.title}
                </div>
                <div
                    className="custom-accordian-btn fs-5 fw-500"
                    onClick={() => setActiveAccordian(isActve ? "" : index)}
                >
                    {" "}
                    <span>{isActve ? "-" : "+"}</span>{" "}
                </div>
            </div>
            <div className={`pe-md-5 ${isActve ? "d-block" : "d-none"}`}>
                <p className="paragraph2 mt-2 pe-md-5 mb-0" style={{ fontSize: '13.5px' }}
                    dangerouslySetInnerHTML={{ __html: acc_data?.subtitle }}
                />

            </div>
        </div>
    );
};

export default PropreneurExperience;
