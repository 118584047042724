import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBathtub, faBed, faCalendarDays, faCar, faCompass, faEnvelope, faHouseChimneyWindow, faHouseCircleCheck, faLayerGroup, faMap, faPhone, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import videoIcon from "../../../assets/Buy Page/Group 3992.png"
import imageIcon from "../../../assets/Buy Page/Group 3996.png"
import planIcon from "../../../assets/Buy Page/Group 3994.png"
import MapIcon from "../../../assets/Buy Page/Group 3990.png"
import MapThumbnail from "../../../assets/Buy Page/map_thumbnail.png"
import LayoutThumbnail from "../../../assets/Buy Page/floor-plan-img1.png"

import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import VerticlePropertyCard from '../../PropreneurPage/VerticlePropertyCard'
import config, { axiosbaseurl } from '../../../config'
import useThrottle from '../../../Custom Hooks/useThrottle'
import CustomLoader from '../../CustomLoader';
import PropertyDetailMap from '../components/PropertyDetailMap';
import ContactPropreneurForm from '../components/ContactPropreneurForm';
import secureLocalStorage from 'react-secure-storage'
import toast, { Toaster } from 'react-hot-toast'
import { customToastOptions } from '../../../Styles/popup_style'
import moment from 'moment'

const RentDetailpage = () => {

    const [propertyData, setPropertyData] = useState({});
    const [similarProps, setSimilarProps] = useState([]);
    const [neighbourProperties, setNeighbourProperties] = useState([]);
    const [propreneurList, setPropreneurList] = useState([]);
    const [slider_images, setSliderImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [liking, setLiking] = useState(false);
    const [active_tab, setActiveTab] = useState(1);

    const locationData = useLocation()
    const navigate = useNavigate()
    const { property_id } = useParams()

    const sliderRef = useRef();

    const tab1Ref = useRef(null)
    const tab2Ref = useRef(null)
    const tab3Ref = useRef(null)
    const tab4Ref = useRef(null)
    const tab5Ref = useRef(null)
    const tab6Ref = useRef(null)

    const credentials = secureLocalStorage.getItem('credentials')

    // throttle for tab functionality
    const handleScroll = useThrottle(handleScrollChangeTab, 300);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const tab_items = [
        {
            id: 1,
            divRef: tab1Ref,
            disabled: false,
            title: "Overview"
        },
        {
            id: 2,
            divRef: tab2Ref,
            disabled: false,
            title: "Property Info"
        },
        {
            id: 3,
            divRef: tab3Ref,
            disabled: false,
            title: "Location"
        },
        {
            id: 4,
            divRef: tab4Ref,
            disabled: true,
            title: "Visiting Hours",
        },
        {
            id: 5,
            divRef: tab5Ref,
            disabled: false,
            title: "Similar Rented Properties"
        },
        {
            id: 6,
            divRef: tab6Ref,
            disabled: false,
            title: "Neighbourhood"
        },
    ]

    function scrollToTabElement(divRef, divId) {
        const { offsetTop } = divRef.current
        window.scrollTo({ top: offsetTop - 140, behavior: 'instant' })
        setActiveTab(divId)
    }

    function getFinalScroll(divRef) {
        const { offsetTop } = divRef.current
        return (offsetTop - 140)
    }

    function handleScrollChangeTab() {
        if (window.scrollY < getFinalScroll(tab2Ref)) {
            setActiveTab(1)
        } else if (window.scrollY < getFinalScroll(tab3Ref)) {
            setActiveTab(2)
        } else if (window.scrollY < getFinalScroll(tab5Ref)) {
            setActiveTab(3)
        } else if (window.scrollY < getFinalScroll(tab6Ref)) {
            setActiveTab(5)
        } else {
            setActiveTab(6)
        }
    }
    // =================================
    // main functionality for fetching data and convert it as per requirements
    useEffect(() => {

        const controller = new AbortController();
        async function getPropExpData() {
            try {
                setLoading(true);
                const sendData = {
                    property_id,
                    user_id: credentials ? credentials?.user_id : 0
                }
                const { data } = await axiosbaseurl.post(`/propertiesData`, sendData);

                const all_property_data = await axiosbaseurl.post('/properties', sendData)

                const curr_pro = data.data[0]

                setSliderImages(curr_pro?.image?.filter(image => image?.upload_type === 'image'))


                const neighbourhoods = await axiosbaseurl.post('/localities', { city_id: curr_pro?.city })

                // filtering simiral properties
                const propertyByType = all_property_data.data.data
                    ?.filter(pro => (
                        (pro.property_on === curr_pro?.property_on) &&
                        (
                            (pro.price_per_month <= curr_pro?.price_per_month + 5000 && pro.price_per_month >= curr_pro?.price_per_month - 5000) &&
                            (pro.bathroom <= curr_pro?.bathroom + 1 && pro.bathroom >= curr_pro?.bathroom - 1) &&
                            (pro.balcony <= curr_pro?.balcony + 1 && pro.balcony >= curr_pro?.balcony - 1) &&
                            (pro.floor <= curr_pro?.floor + 1 && pro.floor >= curr_pro?.floor - 1)
                        ) &&
                        (pro.id !== curr_pro?.id)
                    )
                    )?.map(prop => ({
                        ...prop,
                        image: prop.image?.find(image => image?.upload_type === 'image')?.upload_name,
                        price: prop?.price_per_month 
                    }))

                setSimilarProps(propertyByType)
                // ==============================================

                // neighbourhoods properties
                let neigh_props = neighbourhoods?.data?.data
                    ?.filter(item => item.id !== curr_pro?.neighbourhood)

                // neigh_props = all_property_data.data.data
                //     ?.filter(property => property.id !== property_id)
                //     ?.filter(property => neigh_props.includes(property.neighbourhood))
                // console.log(neigh_props)
                setNeighbourProperties(neigh_props)
                // ==============================================

                const uniqueProprener = propertyByType.reduce((acc, current) => {
                    if (!acc.some(item => item.propreneur_id === current.propreneur_id) && current?.propreneurDetails) {
                        acc.push(current);
                    }
                    return acc;
                }, []);
                const propreneur_list = uniqueProprener?.map(item => ({ id: item?.propreneur_id, ...item?.propreneurDetails }))

                // console.log('propreneur_list', uniqueProprener, propreneur_list)
                setPropreneurList(propreneur_list)

                if (data.success == true) {
                    setPropertyData(data.data[0]);
                    // console.log(data.data[0]);
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
        }

        getPropExpData();

        return () => {
            controller.abort();
        };
    }, [locationData.pathname]);

    const setting = {
        dots: false,
        infinite: true,
        speed: 300,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 3000,
    mobileFirst:true,
    slidesToShow: 1,
        slidesToScroll: 1,
    }

    const { propreneurDetails } = propertyData;

    const galary_thumbnail_img = propertyData?.image?.filter(img => img.upload_type === 'image')[0]?.upload_name
    const GalaryData = [
        {
            id: 1,
            title: "VIDEO",
            slug: "videos",
            icon: videoIcon,
            image: galary_thumbnail_img
        },
        {
            id: 2,
            title: "PHOTOS",
            slug: "photos",
            icon: imageIcon,
            image: galary_thumbnail_img
        },
        {
            id: 3,
            title: "PLAN",
            slug: "floor-plans",
            icon: planIcon,
            image: LayoutThumbnail
        },
        {
            id: 4,
            title: "MAP",
            slug: "map",
            icon: MapIcon,
            image: MapThumbnail
        }
    ]

    // handle like change property functionality
    const handleAddFavourite = async (property_id, islike) => {
        if (credentials) {
            try {
                setLiking(true)
                const formData = {
                    user_id: credentials?.user_id,
                    property_id,
                    islike
                }

                const { data } = await axiosbaseurl.post('/addfavourite', formData)

                const updated_data = data.data.find(prop => prop.id === propertyData.id)
                setPropertyData(updated_data)
                setLiking(false)
            } catch (error) {

            }
        } else {
            toast.error('Login Required !')
        }
    }

    const getFormatedTime = (time) => {
        return (moment(time, 'HH:mm').format('hh:mm A'))
    }

    return (
        <div>
            {loading && <CustomLoader />}
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />

            <section>
                <div className="container" style={{ maxWidth: "1260px" }}>
                    <div className="bd-navbar-fixed">
                        <div className="Detail-row pt-90">
                            <div>
                                <div className="bd-main-head-text pb-10 pt-10">
                                    <span className='font-saira fw-500' onClick={() => navigate(`${config.appUrl}property/home-for-rent`)}>
                                        <FontAwesomeIcon style={{ color: "#F2BE1A" }}
                                            className="pr-15" icon={faArrowLeft} /> Back to Search
                                    </span>
                                </div>
                            </div>
                            <div className="header-row">
                                {
                                    tab_items.map((tab) => {
                                        return (
                                            <div key={tab.id} className="bd-header-content">
                                                <button
                                                    // disabled={tab.disabled}
                                                    onClick={() => {
                                                        tab.disabled ?
                                                            navigate(`${config.appUrl}property/schedule-visit/${propertyData?.id}`) : scrollToTabElement(tab.divRef, tab.id)
                                                    }}
                                                    className={`${active_tab === tab.id && 'active_tab_btn'}`}>
                                                    {tab.title}
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className='pt-md-5'>
                        <div className="row justify-content-center mt-5 pt-md-5">
                            <div className="col-lg-8 col-11 mt-md-5">     { /*  Left FlexBox  */}
                                <div className='position-relative slider_img_container'>
                                    <Slider {...setting} ref={sliderRef}>
                                        {
                                            slider_images?.map(sImage => {
                                                return (
                                                    <div key={sImage?.upload_name} className='slider_img_container' >
                                                        <img className="slider-img w-100 shadow-sm" src={sImage?.upload_name} alt="" />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                    <div className='slider-btns2'>
                                        <button onClick={() => sliderRef?.current?.slickPrev()}>
                                            <i className="fa fa-chevron-left "></i></button>
                                        <button onClick={() => sliderRef?.current?.slickNext()}>
                                            <i className="fa fa-chevron-right"></i></button>
                                    </div>

                                    <div className='slider_property_type font-saira fw-600 shadow-sm'>
                                        for {propertyData?.property_on}
                                    </div>
                                    <div className='slider_property_type slider_virtual_tour shadow-sm'>
                                        <Link to={`${config.appUrl}property-showcase/rent/${property_id}/${'virtual-tour'}`}
                                            className='text-decoration-none text-white font-saira fw-600'>
                                            <i className='bi bi-dpad-fill text-yellow me-2'></i>
                                            virtual tour
                                        </Link>
                                    </div>

                                    <div className='slider_share_like_btn'>
                                        <button
                                            disabled={liking}
                                            onClick={() =>
                                                handleAddFavourite(propertyData?.id, propertyData?.favouriteproperty)}>
                                            {
                                                liking ?
                                                    <i className='bi bi-arrow-clockwise rotate-icon text-white'></i>
                                                    :
                                                    <i className={`bi bi-suit-heart${propertyData?.favouriteproperty ? '-fill' : ''}  text-${propertyData?.favouriteproperty ? 'yellow' : 'white'}`}></i>
                                            }
                                        </button>

                                        <button 
                                            className="ms-2" 
                                            onClick={() => {
                                                if (navigator.share) {
                                                    navigator.share({
                                                        title: document.title,
                                                        url: window.location.href,
                                                    })
                                                    .then(() => console.log("URL shared successfully!"))
                                                    .catch((error) => console.error("Error sharing URL:", error));
                                                } else {
                                                    alert("Sharing is not supported on this browser.");
                                                }
                                            }}
                                        >
                                            <i className="bi bi-share-fill"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="pt-25">
                                    <div className='row mb-5'>
                                        {
                                            GalaryData.map(galaryData => {
                                                return <GalaryCard key={galaryData.id} galaryData={galaryData} property_id={property_id} />
                                            })
                                        }
                                    </div>
                                </div>

                                <div>
                                    <div className="pt-4 pb-15">
                                        <h1 className="bd-rent-detail font-saira mb-0"> &#8377; {propertyData?.price_per_month?.toLocaleString()} </h1>
                                        <div className="yellow-line"></div>
                                    </div>
                                    <div className="bd-description-detail mt-3">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className='bg-custom-light p-4'>
                                                    <h5 className="mb-0 main-area font-saira">
                                                        {propertyData?.address?.split(',')?.slice(1)}
                                                    </h5>
                                                    <p className="mb-0 main-detail-area font-saira">
                                                        {propertyData?.address}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-5 mt-md-0 mt-3">
                                                <div className='bg-custom-light p-4 d-flex justify-content-between text-center'>
                                                    <div className="bd-main-details" >
                                                        <FontAwesomeIcon icon={faBed} className="bd-icons-detail" />
                                                        <div className='font-saira paragraph fw-500'>{propertyData?.bhk}</div>
                                                    </div>
                                                    <div className="bd-main-details">
                                                        <FontAwesomeIcon className="bd-icons-detail" icon={faBathtub} />
                                                        <div className='font-saira paragraph fw-500'>{propertyData?.bathroom} Baths</div>
                                                    </div>
                                                    <div className="bd-main-details">
                                                        <FontAwesomeIcon className="bd-icons-detail" icon={faMap} />
                                                        <div className='font-saira paragraph fw-500'>{propertyData?.built_area} SqFt</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-40" ref={tab1Ref}>
                                    <div>
                                        <div className="pb-25">
                                            <div className="bd-underscore-overview">
                                                <h3 className="bd-header-overview">Overview</h3>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <OverviewCard image={faBed} title={"Bedrooms"} count={propertyData?.bhk} />
                                            <OverviewCard image={faBathtub} title={"Bathroom"} count={propertyData?.bathroom} />
                                            <OverviewCard image={faHouseChimneyWindow} title={"Balconies"} count={propertyData?.balcony} />
                                            <OverviewCard image={faCompass} title={"Facing"} count={propertyData?.facing} />
                                            <OverviewCard image={faMap} title={"Buildup Area"} count={`${propertyData?.built_area} Sq Ft`} />
                                            <OverviewCard image={faLayerGroup} title={"Floor"} count={`${propertyData?.floor}th`} />
                                            <OverviewCard image={faHouseCircleCheck} title={"Sale Type"} count={propertyData?.sales_type} />
                                            <OverviewCard image={faCar} title={"Parking"} count={propertyData?.parking} />
                                            <OverviewCard image={faScrewdriverWrench} title={"Maintenance"} count={"5 Per Sq Ft"} />
                                            <OverviewCard image={faCalendarDays} title={"Possession Date"}
                                                count={`${propertyData?.possesion_month}, ${propertyData?.possesion_year}`} />
                                        </div>

                                    </div>
                                </div>


                                <div className="pt-40 " ref={tab2Ref}>
                                    <div className="pb-2">
                                        <div className="bd-underscore-overview">
                                            <h2 className="bd-header-overview">Property Info</h2>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className="bd-info-agent pt-15"
                                            dangerouslySetInnerHTML={{ __html: propertyData?.property_description }}
                                        />
                                        {/* <ReadMore initialLength={520}>
                                            {propertyData?.property_description}
                                        </ReadMore> */}
                                    </div>
                                </div>

                                <div className='mb-md-0 mb-5' ref={tab3Ref}>
                                    <div className="pb-40 pt-50">
                                        <div className="bd-underscore-overview">
                                            <h2 className="bd-header-overview">Location</h2>
                                        </div>
                                    </div>
                                    <PropertyDetailMap
                                        latitude={propertyData?.latitude}
                                        longitude={propertyData?.longitude}
                                        address={propertyData?.address}
                                    />
                                </div>
                            </div>

                            <div className=" col-lg-4 col-11 mt-5">   { /*  Right FlexBox  */}
                                <div className="ms-md-3">
                                    <div className="bd-detail-box">
                                        <div className="header-row bottom-division">
                                            <div className="bd-detail-tittle">
                                                <p className="mb-0 font-saira">Listing Status:</p>
                                            </div>
                                            <div className="bd-detail-type">
                                                Active
                                            </div>
                                        </div>

                                        <div className="header-row bottom-division">
                                            <div className="bd-detail-tittle">
                                                <p className="mb-0 font-saira">Day on Market:</p>
                                            </div>
                                            <div className="bd-detail-type">
                                                15 Days
                                            </div>
                                        </div>
                                        <div className="header-row bottom-division">
                                            <div className="bd-detail-tittle">
                                                <p className="mb-0 font-saira">Down Payment:</p>
                                            </div>
                                            <div className="bd-detail-type">
                                                ₹ {propertyData?.down_payment?.toLocaleString()}
                                            </div>
                                        </div>
                                        <div className="header-row bottom-division">
                                            <div className="bd-detail-tittle">
                                                <p className="mb-0 font-saira">Property Type:</p>
                                            </div>
                                            <div className="bd-detail-type">
                                                {propertyData?.property_type}
                                            </div>
                                        </div>
                                        <div className="header-row bottom-division">
                                            <div className="bd-detail-tittle">
                                                <p className="mb-0 font-saira">Property Status:</p>
                                            </div>
                                            <div className="bd-detail-type">
                                                {propertyData?.property_availablity		}
                                            </div>
                                        </div>
                                        <div className="header-row bottom-division">
                                            <div className="bd-detail-tittle">
                                                <p className="mb-0 font-saira">Year Built:</p>
                                            </div>
                                            <div className="bd-detail-type">
                                                {propertyData?.year}
                                            </div>
                                        </div>
                                        <div className="header-row bottom-division">
                                            <div className="bd-detail-tittle">
                                                <p className="mb-0 font-saira">Developer:</p>
                                            </div>
                                            <div className="bd-detail-type">
                                                {propertyData?.developer_name || "N/A"}
                                            </div>
                                        </div>
                                        <div className="header-row bottom-division">
                                            <div className="bd-detail-tittle">
                                                <p className="mb-0 font-saira">City/Neighbourhood:</p>
                                            </div>
                                            <div className="bd-detail-type">
                                                {propertyData?.city_name} | {propertyData?.locality_name}
                                            </div>
                                        </div>
                                        <div className="header-row bottom-division">
                                            <div className="bd-detail-tittle active">
                                                <p className="mb-0 font-saira" style={{ color: "#16A9EA", fontWeight: "bold" }}>Authority:</p>
                                            </div>
                                            <div className="bd-detail-type">
                                                {propertyData?.authority}
                                            </div>
                                        </div>
                                        <div className="header-row bottom-division">
                                            <div className="bd-detail-tittle active">
                                                <p className="mb-0 font-saira" style={{ color: "#16A9EA", fontWeight: "bold" }}>RERA:</p>
                                            </div>
                                            <div className="bd-detail-type">
                                                {propertyData?.rera}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="pt-50 ms-md-3">
                                    <ContactPropreneurForm property_id={propertyData?.id}
                                        propreneur_id={propertyData?.propreneur_id} />
                                </div>

                                <div className="pt-50 ms-md-3">
                                    <div >
                                        {
                                            propertyData?.propreneurDetails &&
                                            <div className="main-row bd-employee-section">
                                                <div className="pr-10">
                                                    <img className="empl-img" src={propreneurDetails?.prop_avatar} alt="" />
                                                </div>
                                                <div>
                                                    <div>
                                                        <p className="mb-1 bd-name-emp font-saira">
                                                            {propreneurDetails?.name}</p>

                                                        <div>
                                                            <a href={`mailto:${propreneurDetails?.email}`} className="mb-1 bd-info font-saira">
                                                                <FontAwesomeIcon style={{ color: "#F2BE1A" }} icon={faEnvelope} className='me-1' /> {propreneurDetails?.email}
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a href={`tel:${propreneurDetails?.contact}`} className="mb-0 bd-info font-saira">
                                                                <FontAwesomeIcon style={{ color: "#F2BE1A" }} icon={faPhone} className='me-1' /> {propreneurDetails?.contact}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="ms-md-3 pt-50">
                                    <div className="bd-list-propusers-details">
                                        <div className="bd-lists">
                                            <div className="bd-underscore-overview">
                                                <h2 className="bd-contact-header font-saira">List of Propreneur</h2>
                                            </div>
                                        </div>
                                        <div className="pt-25">
                                            {
                                                propreneurList?.length ?
                                                    propreneurList?.map((details, index) => {
                                                        return (
                                                            <div key={index} className="main-row pb-20">
                                                                <div>
                                                                    <img className="listPropenure" src={details?.prop_avatar} alt="" />
                                                                </div>
                                                                <div>
                                                                    <Link to={`${config.appUrl}propusers-agent-detail/${details?.id}`} style={{ textDecoration: "none" }}>
                                                                        <div >
                                                                            <p className="bd-list-name mb-0 font-saira">{details.name}
                                                                                <div className="bd-type-name font-saira mb-2"> {'Propreneur'}</div></p>

                                                                            <div>
                                                                                <a href={`mailto:${details?.email}`} className="mb-1 bd-info font-saira bd-info-details">
                                                                                    <FontAwesomeIcon style={{ color: "#122844" }} icon={faEnvelope} className='me-1' /> {details?.email}
                                                                                </a>
                                                                            </div>
                                                                            <div>
                                                                                <a href={`tel:${details?.contact}`} className="mb-0 bd-info font-saira bd-info-details">
                                                                                    <FontAwesomeIcon style={{ color: "#122844" }} icon={faPhone} className='me-1' /> {details?.contact}
                                                                                </a>
                                                                            </div>
                                                                            {/* <p className="bd-info-details mb-0">
                                                                        <FontAwesomeIcon style={{ color: "#122844" }} icon={faEnvelope} className='font-saira me-2' />
                                                                        {details.email}</p>
                                                                    <p className="bd-info-details mb-0">
                                                                        <FontAwesomeIcon style={{ color: "#122844" }} icon={faPhone} className='font-saira me-2' />
                                                                        {details.phone} </p> */}
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div className='mb-3 font-saira'>No Propreneur Found !</div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="ms-md-3 pt-50">
                                    <div className="bd-visiting-section">
                                        <div className="">
                                            <div className="bd-lists">
                                                <div className="bd-underscore-overview">
                                                    <h2 className="bd-contact-header font-saira">Visiting Hours</h2>
                                                </div>
                                            </div>

                                            <div className='px-4'>
                                                {
                                                    propertyData?.visiting_hrs &&

                                                    <div className='px-3 py-2 rounded border border-2 mt-4 text-center fw-500 font-saira text-dark-blue'>
                                                        {getFormatedTime(propertyData?.visiting_hrs[0]?.visiting_from)} - {getFormatedTime(propertyData?.visiting_hrs[2]?.visiting_to)}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="pt-4">
                                            <div className="textCenter">

                                                <Link to={`${config.appUrl}property/schedule-visit/${property_id}`} >
                                                    <button className="bd-visiting-btn font-saira">Schedule Your Visit</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="pt-50" ref={tab5Ref}>
                <div className="container" style={{ maxWidth: "1260px" }}>
                    <div className='px-md-0 px-4'>
                        <div className="d-sm-flex justify-content-between align-items-center pb-30">
                            <div>
                                <div className="bd-underscore-overview">
                                    <h2 className="bd-header-overview"> Similar Rented Properties</h2>
                                </div>
                            </div>
                            <div className='mt-sm-0 mt-4'>
                                <Link to={`${config.appUrl}property/home-for-rent`}
                                    className='font-saira text-decoration-none paragraph sell-all-properties-link fw-600'>
                                    See All Properties…</Link>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                {
                                    similarProps?.map((property, index) => {
                                        return <div key={index} className="col-md-3">
                                            <VerticlePropertyCard property={property} ptype={'rent'} />
                                        </div>
                                    })
                                }
                                {
                                    similarProps.length === 0 &&
                                    <div className="col-12">
                                        <h5 className='fw-500 font-saira paragraph'>Similar homes not found !</h5>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-60 pb-80" ref={tab6Ref}>
                <div className="container" style={{ maxWidth: "1260px" }}>
                    <div className='px-md-0 px-4'>
                        <div className="d-sm-flex justify-content-between align-items-center pb-30">
                            <div>
                                <div className="bd-underscore-overview">
                                    <h2 className="bd-header-overview"> Neighbourhood</h2>
                                </div>
                            </div>
                            <div className='mt-sm-0 mt-4'>
                                {
                                    neighbourProperties.length>0 &&
                                    <Link to={`${config.appUrl}property/neighbourhoods/${neighbourProperties[0].city}`}
                                        state={{ data: 'rent' }}
                                        className='font-saira text-decoration-none paragraph sell-all-properties-link fw-600'>
                                        See All Locations…</Link>
                                }
                            </div>
                        </div>
                        <div>
                            <div className="row overlay-card property-overlay-card">
                                {
                                    neighbourProperties?.slice(0, 4)?.map(neighbour_prop => {
                                        return (
                                            <div className="col-lg-3 col-md-4 col-sm-6 mb-4 mb-md-0" key={neighbour_prop.id}>
                                                <div className="overlay-n position-reletive overflow-hidden">
                                                    <img
                                                        src={neighbour_prop.image}
                                                        alt=""
                                                        className="img-fluid w-100"
                                                    />
                                                    <Link to={
                                                        `${config.appUrl}property/home-for-rent?type=neighbourhood&search_query=${neighbour_prop?.locality_name}`
                                                    }>
                                                        <span className="text-yellow font-saira">{neighbour_prop.locality_name}</span>
                                                    </Link>

                                                    <div className="city_icon pe-1">
                                                        <i className="bi bi-bicycle lh-sm"></i>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    neighbourProperties.length === 0 &&
                                    <div>
                                        <h5 className='paragraph mb-0'>No Neighbourhood Available!</h5>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}


const GalaryCard = ({ galaryData, property_id }) => {
    return (
        <div className="col-md-3 col-6 mt-md-0 mt-4">
            <Link to={`${config.appUrl}property-showcase/rent/${property_id}/${galaryData.slug}`} className='text-decoration-none'>
                <div className='bd-tour-section'
                    style={{
                        backgroundImage: `linear-gradient(#11111170, #11111170),url(${galaryData?.image})`,
                    }}
                >
                    <div className='text-center'>
                        <img className="image-icons-details" src={galaryData.icon} alt="" />
                        <h6 className='text-white fw-500 text-uppercase font-saira mb-0 mt-2'>{galaryData.title}</h6>
                    </div>
                </div>
            </Link>
        </div>
    )
}

const OverviewCard = ({ image, title, count }) => {
    return (
        <div className='col-md-3 col-sm-6 mb-4'>
            <div className='bg-custom-light align-items-center d-flex px-3 py-2'>
                <div>
                    <FontAwesomeIcon icon={image} className='fs-4 paragraph' />
                </div>
                <div className='ms-3'>
                    <h6 className='font-saira mb-0 fw-600 text-dark-blue'>{count}</h6>
                    <div className='font-saira fw-500 paragraph mb-0 lh-sm mt-1'>{title}</div>
                </div>
            </div>
        </div>
    )
}





export default RentDetailpage