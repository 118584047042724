import React from "react";
import "../../Styles/LandingPageStyles/service.css";
import { Link } from "react-router-dom";
import config from "../../config";

function Services({ serviceData, heading }) {

  const links = ['property/home-for-sale', `propreneur-experience`, 'property/home-for-rent']
  // const links = ['', `propreneur-experience`, '']
  
  return (
    <>
      <div className="bd-service-rect pt-20 pb-50 ">
        <div className="auto-container">
        <section >
          <div>
            <div>
              <div>
                <div className="bd-main-service-section pt-40">
                  <div className="bd-dashed-line mr-10"></div>
                  <div>
                    <p className="bd-service-first-heading">{heading?.title}</p>
                  </div>
                  <div>
                    <div className="bd-dashed-line ml-10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div>
            <div className="textCenter">
              <h2 className="bd-service-main-heading">
                {heading?.subtitle}
              </h2>
            </div>
          </div>
        </section>

        <section className=" bd-service-boxes">
          <div className="row mt-5">
            {serviceData?.slice(0, 3).map((item, index) => {
              return (
                <div className="col-md-4 mb-md-0 mb-4" key={item.id}>
                  <div
                    className={`bd-service-box-1 mr-30 mt-20 mb-20`}
                  >
                    <div>
                      <div className="pb-30">
                        <img className="w100 home-property-type-image" src={item.image} alt="Properties" />
                      </div>
                      <div>
                        <h4 className="bd-card-head">{item.subtitle}</h4>
                        <div className="yellow-line"></div>
                      </div>
                      <div className="pb-10 pt-15">
                        <p className="bd-text-info" dangerouslySetInnerHTML={{ __html: item.description }}>
                        </p>
                      </div>
                      <div className="mb-10">
                        <Link to={`${config.appUrl}${links[index]}`} className="bd-search-btn py-2 text-decoration-none font-saira">
                          {item.title}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          </div>
        </section>

        </div>
      </div>
    </>
  );
}

export default Services;
