import React, { useState, useEffect, useContext } from 'react'

import { Link } from "react-router-dom"
import config, { axiosbaseurl } from '../../config'
import CustomLoader from '../CustomLoader'
import { AppGlobalContext } from '../../GlobalContext'

const AllProprenuers = () => {
    const [loading, setLoading] = useState(false)
    const [sectionsData, setSectionsData] = useState([])
    const [allData, setAllData] = useState([])
    const [page, setPage] = useState(1)
    const [activeFilter, setActiveFilter] = useState('')
    const [isShown, setIsShown] = useState(false)

    const [selectedAgentType, setSelectedAgentType] = useState("")
    const [selectedPropertyType, setSelectedPropertyType] = useState("")
    const [selectedExperience, setSelectedExperience] = useState("")
    const [selectedLanguage, setSelectedLanguage] = useState("")
    const [selectedLocation, setSelectedLocation] = useState("")
    const [languageOptions, setLanguageOptions] = useState([])

    const { cities_options } = useContext(AppGlobalContext)

    useEffect(() => {
        const controller = new AbortController();
        async function getProprenuer() {
            try {
                setLoading(true)
                const { data } = await axiosbaseurl.get('/proprenuers');

                if (data.success == true) {
                    setAllData(data.data.data)
                    setSectionsData(data.data.data)

                    let allLanguage = ""
                    data.data.data.forEach((item, index, propreneurs) => {
                        allLanguage += (item.skills + (propreneurs.length - 1 === index ? '' : ', '))
                    });
                    const langOption = Array.from(new Set([...allLanguage.split(', ')]))
                    // console.log('allLanguage ', langOption)
                    setLanguageOptions(langOption)
                }
                setLoading(false)

            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        getProprenuer()
        return () => {
            setAllData([])
            setSectionsData([])
            controller.abort();
        };
    }, [])


    const [showSocialIcons, setShowSocialIcons] = useState(Array.from({ length: sectionsData?.length }).fill(false));

    useEffect(() => {

        function handleClickOutside(event) {
            if (!event.target.closest('.social-icons')) {
                // Close all social icons
                setShowSocialIcons(Array.from({ length: sectionsData?.length }).fill(false));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sectionsData]);

    // const toggleSocialIcons = (rowIndex) => {
    //     const newSocialIconsState = [...showSocialIcons];
    //     newSocialIconsState[rowIndex] = !newSocialIconsState[rowIndex];
    //     setShowSocialIcons(newSocialIconsState);
    // };

    const total_cards = 8

    const changePage = (pagecount) => {
        setPage(pagecount)
        window.scrollTo(0, 0)
    }

    // =================================================
    // Filter Propreneur loginF

    const getSearchedData = (data, filters) => {
        const { agentType, propertyType, experience, language, location } = filters;

        return data.filter((prop) => {

            const isAgentTypeMatch =
                agentType === '' || prop?.looking_to_propuser?.toLowerCase() == agentType.toLowerCase();

            const isPropertyTypeMatch = propertyType === '' ||
                prop.expertise?.toLowerCase() === propertyType.toLowerCase();

            const isExperienceMatch = experience === '' || prop.total_experience == experience;

            const isLanguageMatch =
                language === '' ||
                prop.skills?.toLowerCase().includes(language.toLowerCase())

            const isLocationMatch = location === '' || prop?.city == location

            return isAgentTypeMatch && isPropertyTypeMatch && isExperienceMatch && isLanguageMatch && isLocationMatch;
        });
    };

    const handleFilterChange = () => {
        const filters = {
            agentType: selectedAgentType,
            propertyType: selectedPropertyType,
            experience: selectedExperience,
            language: selectedLanguage,
            location: selectedLocation
        };
        setLoading(true)
        setTimeout(() => {
            const filteredData = getSearchedData(allData, filters);
            setSectionsData(filteredData);
            setLoading(false)
        }, 1000)
    };

    // Update the filtered data whenever any of the filter values change
    useEffect(() => {
        if (selectedAgentType || selectedPropertyType || selectedExperience || selectedLanguage || selectedLocation)
            handleFilterChange();
    }, [selectedAgentType, selectedPropertyType, selectedExperience, selectedLanguage, selectedLocation]);

    const handleResetFilter = () => {
        window.location.reload()
    };

    // sorting

    const handleClick = event => {
        setIsShown(current => !current);
    };

    const sortAlphabetically = (name) => {
        setActiveFilter(name)
        const sortedNames = [...sectionsData].sort((a, b) => a[name]?.localeCompare(b[name]));
        setSectionsData(sortedNames);
    };

    // calc
    const pagination_calc = (page * total_cards - total_cards)
    const currentData = sectionsData?.slice(pagination_calc, page * total_cards) || []

    return (
        <div>
            {loading && <CustomLoader />}

            <section className="pt-90 mt-4">
                <div>
                    <div className="container" style={{ maxWidth: "1300px" }}>
                        <h3 className="bd-state-name font-saira">Proprenuers</h3>

                        <div className="row align-items-center">
                            <div className="col-md-8 pe-0">
                                <div className="d-md-flex my-3 border-bottom" >
                                    <div>
                                        <select className='filter-select font-saira active-filter'
                                            defaultValue={selectedAgentType}
                                            onChange={(e) => setSelectedAgentType(e.target.value)} >
                                            <option value={''}>Agents</option>
                                            <option value={'With Team'}>Team</option>
                                            <option value={'Individual Agent'}>Individual   </option>
                                        </select>
                                    </div>

                                    <select className='filter-select font-saira ms-md-4'
                                        defaultValue={selectedPropertyType}
                                        onChange={(e) => setSelectedPropertyType(e.target.value)} >
                                        <option value={''}>Property Type</option>
                                        <option value={'Residential'}>Residential</option>
                                        <option value={'Commercial'}>Commercial</option>
                                        <option value={'Other'}>Other</option>
                                    </select>

                                    <select className='filter-select font-saira ms-md-4'
                                        defaultValue={selectedExperience}
                                        onChange={(e) => setSelectedExperience(e.target.value)} >
                                        <option value={''}>By Experience</option>
                                        <option value={'1'}>1 Year</option>
                                        <option value={'2'}>2 Year</option>
                                        <option value={'3'}>3 Year</option>
                                        <option value={'4'}>4 Year</option>
                                        <option value={'5'}>5 Year</option>
                                        <option value={'6'}>6 Year</option>
                                        <option value={'7'}>7 Year</option>
                                        <option value={'8'}>8 Year</option>
                                        <option value={'9'}>9 Year</option>
                                        <option value={'10'}>10 Year</option>
                                    </select>

                                    <select className='filter-select font-saira ms-md-4'
                                        defaultValue={selectedLanguage}
                                        onChange={(e) => setSelectedLanguage(e.target.value)} >
                                        <option value="">Language</option>
                                        {
                                            languageOptions.map(lang => (
                                                <option key={lang} value={lang}>{lang}</option>
                                            ))
                                        }
                                        {/* <option value={'Hindi'}>Hindi</option>
                                        <option value={'Gujarati'}>Gujarati</option>
                                        <option value={'Marathi'}>Marathi</option> */}
                                    </select>

                                    <select className='filter-select font-saira ms-md-4'
                                        defaultValue={selectedLocation}
                                        onChange={(e) => setSelectedLocation(e.target.value)} >
                                        <option value="">Location</option>
                                        {
                                            cities_options?.map((city) => {
                                                return (
                                                    <option key={city.id} value={city.id}>
                                                        {city.city_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className='col-md-4 mb-md-0 mb-4'>

                                <div className='d-flex justify-content-md-end justify-content-center' >
                                    <button onClick={handleResetFilter}
                                        disabled={sectionsData.length === allData.length}
                                        className='blue-btn rounded-3  py-2 px-3 me-2'>
                                        <i className='bi bi-arrow-clockwise text-yellow me-md-2'></i>
                                        <span className='d-md-inline d-none font-saira'>Reset</span>
                                    </button>
                                    <div className="bd-infor-header-section2">
                                        <div className="bd-whole-infor-header rounded-3 position-relative" >
                                            <a className="mb-0 dropdown-buttom font-saira" onClick={handleClick}>Sort by Propreneur Name
                                                <i htmlFor='purpose1' className='fa fa-chevron-down text-yellow pl-50'></i>
                                            </a>
                                            {isShown && (
                                                <div className="dropdown-content2 dropdown-box bd-content-box1">
                                                    <div className="bd-box-filter-section">
                                                        <div>
                                                            <p className={`bd-dropdown-list font-saira ${activeFilter == 'fname' && 'active'}`}
                                                                onClick={() => sortAlphabetically('fname')}>
                                                                Propreneur First Name A–Z</p>
                                                            <p
                                                                className={`mb-0 bd-dropdown-list font-saira ${activeFilter == 'lname' && 'active'}`}
                                                                onClick={() => sortAlphabetically('lname')}
                                                            >Propreneur Last Name A–Z</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container" style={{ maxWidth: "1300px" }}>
                    <div>
                        <div className="row justify-content-md-start justify-content-center">


                            {currentData?.map((section, index) => (

                                <div key={index} className='col-xl-3 col-lg-4 col-sm-6 col-11'>
                                    <div className=' mb-25 propsection'>
                                        <div>
                                            <Link to={`${config.appUrl}propusers-agent-detail/${section.id}`}>
                                                <div className='propsection-img'>
                                                    <img src={section.prop_avatar} alt="" style={{ objectFit: 'cover' }} />
                                                </div>
                                            </Link>
                                            <div className='main-image'>
                                                <div className='mainimage-container'>
                                                    <div>
                                                        <p className='color-yellow mainimg-name text-capitalize'>{section.name ? section.name : "No Name"}</p>
                                                        <div className='email-phone'>
                                                            <i className='bi bi-envelope color-yellow'></i>
                                                            <p className='color-white'>
                                                                <a href={`mailto:${section.email}`} className='text-white font-saira text-decoration-none'>
                                                                    {section.email}
                                                                </a>
                                                            </p>
                                                        </div>
                                                        <div className='email-phone'>
                                                            <i className='bi bi-telephone color-yellow'></i>
                                                            <p className='color-white'>
                                                                <a href={`tel:${section.contact}`} className='text-white font-saira text-decoration-none'>
                                                                    {section.contact}
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='icon-background position-relative'
                                                    // onClick={() => toggleSocialIcons(index)}
                                                    >
                                                        <i className='bi bi-share-fill fs-5 color-white'></i>
                                                        {/* {showSocialIcons[index] && */}
                                                        <div className='social-icons'>
                                                            <div className='social-facebook'>
                                                                <a href={section?.facebook} target='_blank' rel="noreferrer" className='text-decoration-none'>
                                                                    <i className='bi bi-facebook color-white'></i>
                                                                </a>
                                                            </div>
                                                            <div className='social-twitter'>
                                                                <a href={section?.twitter} target='_blank' rel="noreferrer" className='text-decoration-none'>
                                                                    <i className='bi bi-twitter-x fs-6 color-white'></i>
                                                                </a>
                                                            </div>
                                                            <div className='social-instagram'>
                                                                <a href={section?.instagram} target='_blank' rel="noreferrer" className='text-decoration-none'>
                                                                    <i className='bi bi-instagram color-white'></i>
                                                                </a>
                                                            </div>
                                                            <div className='social-linkedin'>
                                                                <a href={section?.linkedn} target='_blank' rel="noreferrer" className='text-decoration-none'>
                                                                    <i className='bi bi-linkedin color-white'></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        {/* // } */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}

                            {
                                currentData?.length == 0 &&
                                <h4 className='mt-5 text-md-start text-center'>No Propreneur Found</h4>
                            }
                        </div>

                    </div>
                    <div className="pb-40 ">
                        <div className="container2">

                            <div className="d-md-flex justify-content-between" style={{ justifyContent: "left" }}>
                                <div className='d-flex justify-content-md-start justify-content-center'>
                                    {
                                        [...Array(Math.ceil(sectionsData.length / total_cards))].map((_, pagecount) => {
                                            pagecount += 1
                                            return (
                                                <div key={pagecount} className={`bd-number-pagination font-saira fw-bold d-flex align-items-center me-2 pe-1 ${page === pagecount && 'fw-bold text-dark'}`} onClick={() => changePage(pagecount)}>

                                                    {pagecount < 10 ? ('0' + pagecount) : pagecount}
                                                    {
                                                        page === pagecount &&
                                                        <div className="bd-number-pagination ms-1" >
                                                            <div className="bd-number-dashed "></div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="text-md-end text-center mt-md-0 mt-4">
                                    {
                                        currentData?.length ?
                                            <div>
                                                {/* <p className="bd-text-pagination font-saira">
                                                    Showing {(page * total_cards - total_cards) + 1} to {(page * total_cards)} of {sectionsData?.length} entries ( filtered from {sectionsData?.length} total entries)</p> */}
                                                <p className="bd-text-pagination font-saira">
                                                    Showing {pagination_calc + 1} to {" "}
                                                    {pagination_calc + currentData.length} of  {" "}
                                                    {sectionsData.length} entries {" "}
                                                    ( filtered from {(sectionsData.length)} total entries)
                                                </p>
                                            </div> : null
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </div>
    )
}

export default AllProprenuers