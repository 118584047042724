import React, { useEffect, useRef, useState } from "react";
import useStyles from "../../Styles/LandingPageStyles/startingPageStyles";
import SearchBar from "material-ui-search-bar";
import { Link, useNavigate } from "react-router-dom";

import config from "../../config";
import MorePopup from "./MorePopup";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'

function StartingPage({ homeData }) {

  const [show, setShow] = useState(false);

  const [searchString, setSearchString] = useState("");
  const [listFocusIndex, setListFocusIndex] = useState(0);

  const classes = useStyles();

  const [allCities, setAllCities] = useState([]);
  const [allAddress, setALLAddress] = useState([]);
  const [neighbourhoods, setNeighbourhoods] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const [search_type, setSearchType] = useState('')

  useEffect(() => {
    if(search_type === 'buy'){
      setALLAddress(homeData?.page_data?.address?.filter(add => add?.property_on === 'Sale'))
    }
    else{
      setALLAddress(homeData?.page_data?.address?.filter(add => add?.property_on === 'Rent'))
    }
  },[search_type])

  const navigate = useNavigate();

  useEffect(() => {
    if (homeData) {
      setSearchType('buy')
      setAllCities(homeData?.cities);
      setNeighbourhoods(homeData?.localitities);

      const addresses = []
      homeData?.page_data?.address?.forEach(add => {
        if (!addresses.some(item => item?.address === add?.address))
          addresses.push(add)
      })

      setALLAddress(addresses)
    }

    return () => {
      setAllCities([])
      setNeighbourhoods([])
    };
  }, [homeData]);

  // start showing suggestion based on user input
  const handleStartElasticSearch = (event, search_item = false) => {
    console.log(event);
    const search_string = event.toLowerCase()

    if (search_string.length) {
      const city_suggest = allCities.filter(city => city.city_name?.toLowerCase().includes(search_string))
      const neighbour_suggest = neighbourhoods.filter(neigh => neigh.locality_name?.toLowerCase().includes(search_string))
      const address_suggest = allAddress.filter(address => address.address?.toLowerCase().includes(search_string))

      const all_suggestions = [
        ...getSuggestions(address_suggest, 'address', search_string, ''),
        ...getSuggestions(neighbour_suggest, 'locality_name', search_string, 'neighbourhood'),
        ...getSuggestions(city_suggest, 'city_name', search_string, 'city')
      ]
      setSearchSuggestions(all_suggestions)
    } else {
      setSearchSuggestions([])
    }

    setSearchString(event.trim())

    if (search_item) {
      const ptype = (search_type === 'buy') ? 'sale' : 'rent';

      if (search_item === 'neighbourhood') {
        navigate(`${config.appUrl}property/home-for-${ptype}?type=neighbourhood&search_query=${event}`)
      } else
        navigate(`${config.appUrl}property/home-for-${ptype}?search_query=${event}`)
    }

    if (search_string.length === 0)
      setListFocusIndex(0)
  }

  // create suggestion data
  function getSuggestions(options, field_name, search_string, type) {
    return options.map(option => {
      const option_name = option[field_name].toLowerCase();
      const index = option_name.indexOf(search_string);

      const beforeHighlight = option_name.substring(0, index);
      const afterHighlight = option_name.substring(index + (search_string.length));
      return ({
        before: beforeHighlight,
        middle: search_string,
        after: afterHighlight,
        type: type
      })
    })
  }

  const [selectedSearchTerms, setSelectedSearchTerms] = useState([]);


  const handleTagsChange = (newTags) => {
    if (newTags.length > selectedSearchTerms.length) {
      const latestTag = newTags[newTags.length - 1];
      handleStartElasticSearch(latestTag);
    }
    setSelectedSearchTerms(newTags);
  };

  const handleInputChange = (inputValue) => {
    setSearchString(inputValue); // Update the current search string
    handleStartElasticSearch(inputValue); // Fetch suggestions dynamically
  };

  
  const handleAddSearchTerm = (term , type) => {
    if(type === ""){
      const ptype = (search_type === 'buy') ? 'sale' : 'rent';
      navigate(`${config.appUrl}property/home-for-${ptype}?search_query=${term}`)
    }
    if (term && !selectedSearchTerms.includes(term)) {
      setSelectedSearchTerms([...selectedSearchTerms, term]);
      setSearchString(""); // Clear the search bar
      setSearchSuggestions([]); // Reset suggestions
    }
  };
  

  return (
    <>

      <div>
        <div
          className="bd-banner-home"
          style={{
            backgroundImage: `url(${homeData?.page_data?.first?.image})`,
          }}
        >
          <div className="bd-banner-section">
            <div>
              <div>
                <p className="main-head-banner">
                  <span>
                    {homeData?.page_data?.first?.title?.split(" ").slice(0, 2).join(" ")}
                  </span>{" "}
                  <strong>
                    {homeData?.page_data?.first?.title.split(" ").slice(2).join(" ")}
                  </strong>
                </p>
              </div>
              <div className="display-flex bd-buttons-sell my-4">
                <div>
                  <button className={`bd-buy-capsule border-0 ${search_type === 'buy' && 'bd-active-capsule fw-bold'}`}
                    value={'buy'} onClick={(e) => setSearchType(e.target.value)}>
                    Buy
                  </button>
                </div>
                <div className="pl-10">
                  <button className={`bd-buy-capsule border-0 ${search_type === 'rent' && 'bd-active-capsule fw-bold'}`}
                    value={'rent'} onClick={(e) => setSearchType(e.target.value)}>
                    Rent
                  </button>
                </div>
              </div>
              
              <div className="position-relative">
                <div>
                  <TagsInput 
                      value={selectedSearchTerms} 
                      onChange={handleTagsChange}
                      inputProps={{
                        value: searchString,
                        placeholder : "City, Neighbourhood, Address, Zipcode..",
                        onChange: (e) => handleInputChange(e.target.value),
                      }}  
                  />
                  <div className="Search-Button-Home">
                      <button className="bd-search-button-bar"
                        onClick={() => {
                          const ptype = (search_type === 'buy') ? 'sale' : 'rent';
                          navigate(`${config.appUrl}property/home-for-${ptype}?search_query=${selectedSearchTerms}&type=city`)
                        }}
                      >
                        <i className="fa fa-search search-icons"></i>
                      </button>
                  </div>
                </div>
                <div >
                  {
                    searchString.length > 1 &&
                    <div className="suggestion-box">
                      {
                        searchSuggestions.map((suggestion, index) => {
                          console.log(searchSuggestions);
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                handleAddSearchTerm(suggestion.before + suggestion.middle + suggestion.after , suggestion.type)
                              }
                              className={index === listFocusIndex ? "active_list" : ""}
                            >
                              {suggestion.before}
                              <strong style={{ color: "#f0b14a" }}>{suggestion.middle}</strong>
                              {suggestion.after}
                              {suggestion.type && (
                                <strong className="badge bg-yellow text-dark-blue ms-4">
                                  {suggestion.type}
                                </strong>
                              )}
                            </div>

                          )
                        })
                      }
                      {
                        searchSuggestions.length === 0 &&
                        <div className="text-danger">No Result Found !</div>
                      }
                    </div>
                  }
                </div>
              </div>
              <div className="pt-10 px-md-0 px-2">
                <div className="display-flex align-item-center justify-content-center">
                  <div className="bd-category">Ware house</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">Cold Storage</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">Hospital Land</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">Factory</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">School Land</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category" onClick={() => setShow(true)}>More ...</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MorePopup show={show} setShow={setShow} />
    </>
  );
}

export default StartingPage;
