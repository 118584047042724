import React, { useEffect, useState } from 'react'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';

import icon1 from "../../assets/footer/pointer1.png"

const customMarkerIcon = L.icon({
    iconUrl: icon1,
    iconSize: [20, 30],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

// fetch highway data
const fetchHighways = async (lat, lon) => {
    const query = `
      [out:json];
      (way["highway"](around:500, ${lat}, ${lon});
      );
      out body;`;

    const response = await fetch(`https://overpass-api.de/api/interpreter?data=${encodeURIComponent(query)}`);
    const data = await response.json();
    const selecteHighways = data.elements?.filter(item => item.tags.name)?.map(item => item.tags.name)

    let uniqueHighways = [];

    selecteHighways.forEach((item, i) => {
        if (!uniqueHighways.includes(item))
            uniqueHighways.push(item)
    })

    // console.log('near highway : ', uniqueHighways)
    return uniqueHighways;
};

const fetchBoundaries = async (lat, lon) => {
    const query = `
      [out:json];
      (
        relation["boundary"="administrative"](around:5000, ${lat}, ${lon});
      );
      out body;`;

    const response = await fetch(`https://overpass-api.de/api/interpreter?data=${encodeURIComponent(query)}`);
    const data = await response.json();

    const selecteBoundries = data.elements?.filter(item => item.tags.name)?.map(item => item.tags.name)

    let uniqueBoundries = [];

    selecteBoundries.forEach((item, i) => {
        if (!uniqueBoundries.includes(item))
            uniqueBoundries.push(item)
    })

    // console.log('boundries : ', uniqueBoundries)
    return uniqueBoundries;
};

const LocalityMapView = ({ localityData }) => {

    const [position, setPosition] = useState([]);
    const [locations, setLocation] = useState([]);
    const [highways, setHighways] = useState([]);
    const [boundaries, setBoundaries] = useState([]);
    // const position = [23, 80.962883];

    useEffect(() => {
        if (localityData) {
            const coords = localityData?.coordinates?.split(',')?.length == 2 ?
                localityData?.coordinates?.split(',') :
                ['28.5961', '77.3723']

            const latitude = Number(coords[0]?.match(/[\d.]+/)[0])
            const longitude = Number(coords[1]?.match(/[\d.]+/)[0])

            setPosition([latitude, longitude])
            setLocation([{
                name: localityData?.locality_name,
                latitude: latitude,
                longitude: longitude,
                state: localityData?.state
            }])

            fetchHighways(latitude, longitude).then(setHighways);
            fetchBoundaries(latitude, longitude).then(setBoundaries);
        }
    }, [localityData])

    return (
        <>
            <div className="position-relative">
                <div className="bd-main-section-map locality_map">
                    <div className='w-100 h-100 locality_map locality_map2'>
                        {
                            position?.length > 0 &&
                            <MapContainer
                                center={position}
                                zoom={14}
                                style={{ height: '100%', width: '100%' }}
                                zoomControl={true}
                                scrollWheelZoom={false}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />

                                {locations?.map((location, index) => (
                                    <Marker key={index}
                                        position={[location.latitude, location.longitude]}
                                        icon={customMarkerIcon}
                                    >
                                        <Popup
                                            autoPan={false}
                                            closeButton={false}
                                            offset={[110, 50]}
                                        >
                                            <div className="">
                                                <div>
                                                    <h5 className='font-saira text-dark-blue'>{location.name}</h5>
                                                    <p className="my-1 font-saira fw-bold ">
                                                        {location?.state}
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div>
                                    <h3>{location.name}</h3>
                                    <p>{location.address}</p>
                                    <p>Contact: {location.contact}</p>
                                </div> */}
                                        </Popup>
                                    </Marker>
                                ))}
                            </MapContainer>
                        }
                    </div>
                </div>
                {
                    highways.length > 0 &&
                    <div className="locality_map_detail p-4 bg-white shadow">
                        <h4 className="font-saira fw-600 mb-1 text-dark-blue">Roads & Highways</h4>
                        <div className="yellow-line mb-3"></div>
                        <div>
                            {
                                highways?.slice(0, 5)?.map((item, i) => {
                                    return <p key={i} className="paragraph font-saira mb-1">{item}</p>
                                })
                            }
                            {/* <p className="paragraph font-saira mb-1">NH-24 Highway</p>
                        <p className="paragraph font-saira mb-1">FNG Expressway</p>
                        <p className="paragraph font-saira mb-0">Dadri Highway</p> */}
                        </div>
                    </div>
                }
                {
                    boundaries.length > 0 &&
                    <div className="locality_map_detail2 p-4 bg-white shadow">
                        <h4 className="font-saira fw-600 mb-1 text-dark-blue">Boundaries Near {localityData?.locality_name}</h4>
                        <div className="yellow-line mb-3"></div>
                        <div>
                            {
                                boundaries?.slice(0, 5)?.map((item, i) => {
                                    return <p key={i} className="paragraph font-saira mb-1">{item}</p>
                                })
                            }
                            {/* <p className="paragraph font-saira mb-1">North side Delhi National Capital</p>
                        <p className="paragraph font-saira mb-1">West side Haryana</p>
                        <p className="paragraph font-saira mb-0">East & West Uttar Pradesh</p> */}
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default LocalityMapView