import React from 'react'
import userIcon from "../../../assets/BuyDetailPage/user_icon.png"
import moment from 'moment'

const ViewComment = ({ commentData }) => {
    const image = commentData?.image?.slice(-1) === '/' ? userIcon : commentData?.image;
    return (
        <div className="comment-box position-relative p-4 bg-white mt-3">
            <div className="d-md-flex justify-content-between align-items-center mb-3">
                <div className="user-details d-flex align-items-center">
                    <div><img src={image} alt="user" className='border' /></div>
                    <h5 className="fw-600 mb-0 ms-md-3 ms-2 text-dark-blue">{commentData?.user_name}</h5>
                </div>
                <div className="text-dark fw-500 font-saira mt-md-0 mt-3">
                    <i className="bi bi-calendar text-yellow me-2"></i>
                    {moment(commentData?.created_at).format("MMMM DD YYYY , h:mm a")}
                    {/* 20 February, 2024 */}
                </div>
            </div>
            <div className="paragraph fs-6 font-saira">
                {commentData?.message}
            </div>
        </div>
    )
}

export default ViewComment