import React, { useContext, useState } from 'react'

import propuserlogo from '../../assets/Propusers_logo-White.png';
import '../../Styles/Comman.css';
import { Link, useNavigate } from "react-router-dom"
import config, { axiosbaseurl } from '../../config';
import { AppGlobalContext } from '../../GlobalContext';
// google login
import { useGoogleLogin } from '@react-oauth/google';
import secureLocalStorage from 'react-secure-storage';
import toast, { Toaster } from 'react-hot-toast';
import { customToastOptions } from '../../Styles/popup_style';

const CreateAccountFirst = () => {

    const [registerType, setRegisterType] = useState('')
    const [loading, setLoading] = useState(false);

    const { boardingData } = useContext(AppGlobalContext);


    const handleLoginUser = async (e) => {
        e.preventDefault()
        navigate(`${config.appUrl}register`, {
            state: {
                registerType
            }
        })
    }

    const navigate = useNavigate();

    // Google Login
    const loginByGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            // setUser(codeResponse)

            // console.log('Google Login Success:', codeResponse);

            async function sendUserData() {
                try {
                    setLoading(true)
                    const response = await axiosbaseurl.post('/auth/google', { access_token: codeResponse.access_token })

                    if (response.data.success == true) {
                        toast.success('Login Successfull')
                        secureLocalStorage.setItem("credentials", {
                            user_id: response.data.data[0].id,
                            name: response.data.data[0].name,
                            email: response.data.data[0].email,
                            image: response.data.data[0].avatar,
                            blogs: response.data.data[0].blogs?.map((post) => post.id) || [],
                            news: response.data.data[0].news?.map((post) => post.id) || []
                        })
                        setTimeout(() => {
                            setLoading(false)
                            navigate(config.appUrl)
                        }, 500)
                    }

                    // Handle success or error based on server response
                } catch (error) {
                    console.error('Error logging in:', error);
                }
            }

            sendUserData()

        },
        onError: (error) => console.log('Login Failed:', error)
    });

    const social_icons = boardingData?.signup?.social_media?.split(',') || [0, 0, 0, 0]


    return (
        <div>
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />
            <div
                className='bg-image py-4 row align-items-center justify-content-end mx-0'
                style={{
                    marginTop: "90px",
                    minHeight: '110vh',
                    backgroundImage: `linear-gradient(to right, transparent 20%, #374053), url(${boardingData?.signup?.image})`
                }}
            >
                <div className="col-xl-5 col-lg-6 col-md-8 ms-md-5 px-md-5">

                    <div className='form-container py-5'>
                        <div className='top-form-section'>
                            <img src={propuserlogo} alt=""></img>
                            <h4 className='py-4 pt-5'
                                dangerouslySetInnerHTML={{ __html: boardingData?.signup?.title }}
                            />
                        </div>

                        <form onSubmit={handleLoginUser}>
                            <div className="row">

                                <div className="col-12 mb-3">

                                    <div className='yellow-box mb-3 pe-3'>
                                        <div>
                                            <i className='fa fa-user-secret fs-1 lh-sm text-dark-blue'></i>
                                        </div>
                                        <div className='ms-2 text-dark-blue' style={{ flex: 1 }}>
                                            <select
                                                value={registerType}
                                                onChange={(e) => setRegisterType(e.target.value)}
                                                required
                                                style={{ boxShadow: 'none', cursor: 'pointer' }}
                                                className='form-select border-0 bg-yellow text-dark-blue '>
                                                <option value="">Choose Buyer/Seller</option>
                                                <option value="Buyer">Buyer</option>
                                                <option value="Seller">Seller</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div >
                                <button className='btn-blue w-100' type='submit' >
                                    Continue
                                </button>
                            </div>

                            <div className='d-flex justify-content-evenly mt-5 mb-4  fs-1'>
                                {
                                    social_icons[0] == 1 &&
                                    <div style={{ cursor: 'pointer' }}
                                        onClick={() => loginByGoogle()}
                                    ><i className="fa fa-google color-google"></i></div>
                                }
                                {
                                    social_icons[1] == 1 &&
                                    <div style={{ cursor: 'pointer' }}><i className="fa fa-facebook color-facebook"></i></div>
                                }
                                {
                                    social_icons[2] == 1 &&
                                    <div style={{ cursor: 'pointer' }}><i className="fa fa-linkedin color-linkedin"></i></div>
                                }
                                {
                                    social_icons[3] == 1 &&
                                    <div style={{ cursor: 'pointer' }}><i className="fa fa-apple color-apple"></i></div>
                                }

                            </div>

                            <div className='pt-3 text-center'>
                                <p style={{ color: "white" }}>Already registered?
                                    <Link to={`${config.appUrl}login`}
                                        className='text-decoration-none text-yellow'> Login</Link></p>
                            </div>

                        </form>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default CreateAccountFirst