import React, { useState, useRef, useEffect } from "react";
import propsuserlogo from "../../assets/Propusers_logo-White.png";
import customsvg from "../../assets/Appmarket.png";
import loginsvg from "../../assets/Login.png";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import "../../Styles/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";
import "../../Styles/Responsive/HomeResponsive.css";
import featureicon4 from "../../assets/Key Features Icons/fixed-propuser.png";
import FooterSideBar from "./FooterSideBar";
import secureLocalStorage from "react-secure-storage";

import stringSimilarity from "string-similarity";
import userLogo from "../../assets/BuyDetailPage/user_icon.png"
import NavSearchBar from "./NavSearchBar";
import MarketPopup from "./MarketPopup";
import toast from "react-hot-toast";

function NavBar({  isSearchBar = true, isTransparent = false, homePageData }) {

  const [userDropdown, setUserDropdown] = useState(false);
  const [proprenurDropdown, setProprenurDropdown] = useState(false);
  const [loggedInDropdown, setLoggedInDropdown] = useState(false);
  const [market_popup, setmarket_popup] = useState(false);

  const [isShown, setIsShown] = useState(false);
  const [floatingIcon, setFloatingIcon] = useState(false);

  const [isOpen, setIsopen] = useState(false);

  const url_location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const login_credential = secureLocalStorage.getItem("credentials");
  const { property_id } = useParams()

  // just navigating
  useEffect(() => {
    if (url_location?.pathname.slice(-1) == "/")
      navigate(url_location?.pathname.slice(0, -1));
  }, []);

  // reset state when url changes
  useEffect(() => {
    if (isShown)
      setIsShown(false);
    if (userDropdown)
      setUserDropdown(false)
    if (isOpen)
      setIsopen(false)
    if (proprenurDropdown)
      setProprenurDropdown(false)
    if (market_popup)
      setmarket_popup(false)
  }, [url_location.pathname])

  // for propreneur dropdown
  const handleClick = () => {
    setIsShown((current) => !current);
    setUserDropdown(false);
    setmarket_popup(false)
  };

  // for mobile sidebar
  const ToggleSidebar = () => {
    setIsopen(!isOpen)
  };

  const fontStyle = url_location?.pathname == config.appUrl ? "fs-6" : "font-saira";

  const pagesIncludesFloatBtn = [config.appUrl, `${config.appUrl}home-for-sale/property-details/${property_id}`]

  const userImage = `${login_credential?.image || userLogo}`

  const handleLogout = () => {
      secureLocalStorage.removeItem("credentials");
      toast.success('Logout Successfull')
      setTimeout(() => {
        navigate(config.appUrl);
      }, 1000);
    };

  return (
    <>
      <section>
        <nav
          className={` fixed-top navbar-expand-lg navbar-light
           bg-grey${isTransparent ? "-transparent" : ""}`}
          style={{ paddingBottom: "14px" }}
        >
          <div className="navbar align-items-center px-xxl-5 px-sm-4 px-3">
            <Link
              className="navbar-brand flex-size-header2 bd-responsive-nav2 ps-2"
              to={config.appUrl}
            // style={{ marginRight: "40px" }}
            >
              <img className="main-logo" src={propsuserlogo} alt="logoIcon" />
            </Link>

            {/* Search bar */}
            {isSearchBar && (
              <NavSearchBar />

              // <div className="bd-search-navbar prop-responsive d-md-block d-none">
              //   <div
              //     className="input-group main-searh-input"
              //   >
              //     <input
              //       type="text"
              //       className="form-control font-saira ps-3"
              //       placeholder="City, Neighbourhood, Address, School, Zip, Agent, ID"
              //       aria-label="Recipient's username"
              //       aria-describedby="basic-addon2"
              //       style={{
              //         fontSize: "12px",
              //         border: "none",
              //         color: "rgba(108, 117, 125,0.6)",
              //       }}
              //       value={searchString}
              //       onChange={(e) => setSearchString(e.target.value)}
              //     />
              //     <div className="input-group-append">
              //       <button
              //         className="btn btn-outline-secondary "
              //         type="button"
              //         onClick={handleSearch}
              //         style={{
              //           paddingTop: "2px",
              //           backgroundColor: "#f5c954",
              //           color: "white",
              //           border: "none",
              //           borderBottomLeftRadius: "0px",
              //           borderTopLeftRadius: "0px",
              //         }}
              //       >
              //         <i className="fa fa-search"></i>
              //       </button>
              //     </div>
              //   </div>
              // </div>
            )}
            {/* =================================== */}

            {/* menu icon & user image for mobile view */}
            <div className="d-flex pt-3">

              {login_credential && (
                <div className="profile-img d-md-none">
                  <img
                    src={userImage}
                    alt="usericon"
                    className="rounded-circle bg-yellow"
                    style={{ width: "35px", height: "35px", padding: '1px' }}
                  />

                </div>
              )}

              <button
                className="navbar-toggler toggle-btn bd-side-bars ms-3"
                onClick={ToggleSidebar}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
            </div>
            {/* =================================== */}

            {/* desktop menu */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav justify-content-end ml-auto w100 pt-20 ">
                <li className="nav-item  bottom-line">
                  <Link
                    className={`nav-link text-white ${fontStyle}`}
                    to={`${config.appUrl}property/home-for-sale`}
                  > Buy
                  </Link>
                </li>

                <li className="nav-item nav-responsive mr-5">
                  <span className="nav-link side" style={{ color: "#D5A117" }}> | </span>
                </li>

                <li className="nav-item mr-5  bottom-line">
                  <NavLink
                    className={`nav-link text-white ${fontStyle}`}
                    to={`${config.appUrl}propreneur-experience`}
                  > Sell
                  </NavLink>
                </li>

                <li className="nav-item nav-responsive mr-5">
                  <span className="nav-link side" style={{ color: "#D5A117" }}> | </span>
                </li>

                <li className="nav-item mr-5 bottom-line">
                  <Link
                    className={`nav-link text-white ${fontStyle}`}
                    to={`${config.appUrl}property/home-for-rent`}
                  > Rent
                  </Link>
                </li>

                <li className="nav-item nav-responsive mr-5">
                  <span className="nav-link side" style={{ color: "#D5A117" }}> | </span>
                </li>

                {/* propreneur dropdown */}
                <li className="nav-item mr-5">
                  <div className="dropdown">
                    <a
                      className={`nav-link  ${fontStyle}`}
                      style={{ color: "white", cursor: "pointer" }}
                      onClick={handleClick}
                    >
                      Propreneur
                      <i className={`bi bi-chevron-down text-yellow ms-2`}></i>
                    </a>
                    {isShown && (
                      <div className="dropdown-content shadow py-2">
                        <NavLink
                          to={`${config.appUrl}find-a-propreneur`}
                          className="font-saira profile-popup py-2"
                          style={{ color: "#122844" }}
                        >
                          Find a Propreneur
                        </NavLink>
                        <NavLink
                          to={`${config.appUrl}join-as-a-propreneur`}
                          className="font-saira profile-popup py-2"
                          style={{ color: "#122844" }}
                        >
                          Join as a Propreneur
                        </NavLink>
                      </div>
                    )}
                  </div>
                </li>

                {/* login user dropdown */}
                <li className="nav-item mr-10">
                  {login_credential ? (
                    <div className="dropdown pt-0 d-flex ms-2">
                      <div className="profile-img">
                        <img
                          src={userImage}
                          alt="Icon"
                          className="rounded-circle bg-yellow"
                          style={{ padding: '1px' }}
                        />
                      </div>
                      <div ref={dropdownRef}>
                        <a
                          className="nav-link bottom-line text-yellow "
                          style={{ color: "white", cursor: 'pointer' }}
                          onClick={() => {
                            setUserDropdown(!userDropdown);
                            if (isShown)
                              setIsShown(false);
                            if (market_popup)
                              setmarket_popup(false);
                          }}
                        >
                          {login_credential?.name?.split(' ')[0]}{""}
                        </a>
                        {userDropdown && (
                          <div className="dropdown-content logout-dropdown shadow">
                            {/* /user-profile */}
                            <NavLink
                              to={`${config.appUrl}user-profile`}
                              className={'profile-popup'}
                              style={{ color: "#122844" }}
                            >
                              Account Settings
                            </NavLink>
                            {/* <NavLink
                              to={`${config.appUrl}email-settings`}
                              className="profile-popup py-1"
                              style={{ color: "#122844" }}
                            >
                              Email Settings
                            </NavLink> */}

                            <a onClick={handleLogout} style={{ color: "#122844" }}>
                              Logout
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Link className="nav-link" to={`${config.appUrl}login`}>
                      <img className="icon-size" src={loginsvg} alt="userIcon" />
                    </Link>
                  )}
                </li>

                {/* yellow apps icons */}
                <li className="nav-item mr-10 position-relative">
                  <a className="nav-link" onClick={() => {
                    setmarket_popup(prev => !prev)
                    if (isShown)
                      setIsShown(false);
                    if (userDropdown)
                      setUserDropdown(false)
                    
                  }} style={{ cursor: "pointer" }}>
                    <img className="icon-size" src={customsvg} alt="icon" />
                  </a>
                  <MarketPopup market_popup={market_popup} setmarketPopup={setmarket_popup} />
                </li>

                <FooterSideBar url_location={url_location} />
              </ul>
            </div>
            {/* =================================== */}
          </div>
        </nav>
      </section>

      {/* Menubar for mobile */}
      <div onClick={() => setIsopen(false)} className={`mobile_sidebar_box ${isOpen == true ? "d-block" : "d-none"}`}>
      </div>
      <div className={`sidebar ${isOpen == true ? "active" : ""}`}>
        <div className="sd-header">
          <button className="bd-cross-btn" onClick={ToggleSidebar}>
            <i className="bi bi-x-lg" style={{ lineHeight: 0 }}></i>
          </button>
        </div>
        <div>
          <div className="sd-bodys">
            <Link to={`${config.appUrl}property/home-for-sale`} className="text-white">Buy</Link>
          </div>
          <div className="sd-bodys">
            <Link to={`${config.appUrl}propreneur-experience`} className="text-white">Sell</Link>
          </div>
          <div className="sd-bodys">
            <Link to={`${config.appUrl}property/home-for-rent`} className="text-white">Rent</Link>
          </div>
          <div
            className="sd-bodys d-flex justify-content-between align-items-center"
            onClick={() => setProprenurDropdown(!proprenurDropdown)}
          >
            <a className="text-white">Propreneur</a>
            <i
              className={`bi bi-chevron-${proprenurDropdown ? "up" : "down"
                } text-white`}
            ></i>
          </div>
          <div
            className={`bg-yellow mx-3 ${proprenurDropdown ? "d-block" : "d-none"}`}
          >
            <div className="sd-bodys" style={{ borderBottom: 0 }} >
              <Link
                to={`${config.appUrl}find-a-propreneur`}
                className="text-white"
              >
                Find a Proprenur
              </Link>
            </div>
            <div className="sd-bodys" style={{ borderBottom: 0 }} >
              <Link
                to={`${config.appUrl}join-as-a-propreneur`}
                className="text-white"
              >
                Join as a Proprenur
              </Link>
            </div>
          </div>

          {/* If user is not logged In */}
          {!login_credential && (
            <div className="sd-bodys d-flex align-items-center">
              <img src={loginsvg} alt="userIcon" style={{ width: "24px" }} />
              <Link to={`${config.appUrl}login`} className="text-white ms-3 ">
                {" "}
                Login
              </Link>
            </div>
          )}

          {/* If user is logged In */}
          {login_credential && (
            <>
              <div
                className="sd-bodys d-flex justify-content-between align-items-center"
                onClick={() => setLoggedInDropdown(!loggedInDropdown)}
              >
                <a className="text-white">
                  <img
                    src={userImage}
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "cover",
                      padding: '1px'
                    }}
                    className="rounded-circle me-2 bg-yellow"
                    alt="userIcon"
                  />
                  {login_credential?.name}
                </a>
                <i
                  className={`bi bi-chevron-${loggedInDropdown ? "up" : "down"
                    } text-white`}
                ></i>
              </div>
              <div
                className={`bg-yellow mx-3 ${loggedInDropdown ? "d-block" : "d-none"
                  }`}
              >

                <div className="sd-bodys" style={{ borderBottom: 0 }}>
                  <Link
                    to={`${config.appUrl}user-profile`}
                    className="text-white"
                  >
                    Account Settings
                  </Link>
                </div>
                <div className="sd-bodys" style={{ borderBottom: 0 }}>
                  <Link
                    to={`${config.appUrl}email-settings`}
                    className="text-white"
                  >
                    Email Settings
                  </Link>
                </div>

                <div className="sd-bodys" style={{ borderBottom: 0 }}>
                  <a onClick={handleLogout} className="text-white">
                    Logout
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* =================================== */}


      {/* fixed yellow floating button*/}
      {(pagesIncludesFloatBtn?.includes(url_location?.pathname)) && (
        <div
          className="fixed-propuser d-md-block d-none shadow-sm"
          onClick={() => setFloatingIcon(!floatingIcon)}
        >
          <div className="d-flex align-items-center text-start">
            <div className="key-icon">
              <img src={featureicon4} alt="floating icon" />
            </div>
            <div className={`ms-3 ${floatingIcon ? "d-block" : "d-none"}`}>
              <Link
                to={`${config.appUrl}contact-us`}
                className="text-white text-decoration-none"
              >
                Get Connected
              </Link>
              <Link
                to={`${config.appUrl}contact-us`}
                className=" text-decoration-none"
              >
                <h5 className="text-dark-light mt-2">www.propusers.com</h5>
              </Link>
            </div>
          </div>
        </div>
      )}

    </>
  );
}

export default NavBar;
