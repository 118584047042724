import React, { useEffect, useState } from "react";
import { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";
import "../../assets/Css/Authorities.css";
import Slider from "react-slick";
import AuthorityMap from "./AuthorityMap";
import ReactDatamaps from "react-india-states-map";

import swal from "sweetalert";

const Authorities = () => {

  const [loading, setLoading] = useState(false);
  const [activeDistrict, setActiveDistrict] = useState(null);
  const [activeStateName, setActiveStateName] = useState('Uttar Pradesh');
  const [allStates, setAllStates] = useState([]);
  const [allDistricts, setAllDistricts] = useState([]);
  const [allTehsils, setAllTehsils] = useState([]);
  const [searchState, setSearchState] = useState('');
  const [headingData, setHeadingData] = useState({});

  const [activeState, setactiveState] = useState({
    data: 3,
    name: "Uttar Pradesh"
  });

  // Fetch Initial Data
  useEffect(() => {
    const controller = new AbortController();
    async function fetchAllData() {
      setLoading(true)
      const states = await fetchAllStates()
      const districts = await onSelectState(3)
      await onSelectCity({ id: districts[0]?.id, name: districts[0]?.city_name })
      setLoading(false)

    }
    fetchAllData()
    return () => {
      controller.abort();
    };
  }, []);

  // Fetch Cities on state select
  const fetchAllStates = async () => {
    setLoading(true)
    try {
      const response = await axiosbaseurl.get('/allStates')
      setAllStates(response.data.data.filter(item => item.status === 1));
      setLoading(false)

      return response.data.data
    } catch (error) {
      console.log(error)
    }
  }

  // Fetch Cities on state select
  const onSelectState = async (state_id) => {
    setLoading(true)

    try {
      const response = await axiosbaseurl.post('/authoritiesData', { state_id })
      setAllDistricts(response.data.data[0].cities)
      setHeadingData(response.data.titles[0])
      setLoading(false)

      setTimeout(() => window.scrollTo(0, 700), 1000)
      return (response.data.data[0].cities)
    } catch (error) {
      console.log(error)
    }
  }

  // Fetch Tehsils on city select
  const onSelectCity = async (city) => {
    setActiveDistrict(city);
    setLoading(true)
    try {
      const response = await axiosbaseurl.post('/localityCity', { city_id: city.id })
      setAllTehsils(response.data.data)
      setLoading(false)
      // window.scrollTo(0, 900)
    } catch (error) {
      console.log(error)
    }
  }

  const sliderSettings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 8000,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],

  };


  // Map Component Code
  const stateOnClick = (data, name) => {
    if (data) {
      setActiveStateName(name)
      setactiveState({ data: data.value, name });
      fetchingData(data.value)
    }
  };

  // Search Box
  const handleSearchState = async (e) => {
    e.preventDefault();
    const currState = allStates.find(item => item.state_name.toLowerCase().includes(searchState.toLowerCase()))
    if (currState) {
      // console.log(currState)
      setActiveStateName(currState?.state_name)
      setactiveState({
        data: currState?.id,
        name: currState?.state_name
      });
      fetchingData(currState?.id)
    } else {
      swal("State Not Found!", "", "warning")
    }
  }

  const fetchingData = async (state_id) => {
    const districts = await onSelectState(state_id)
    await onSelectCity({ id: districts[0]?.id, name: districts[0]?.city_name })
  }


  return (
    <div>

      {loading && <CustomLoader />}

      <div className="pt-90 ">
        <div className=" ">
          <div className="authority-banner ">
            <div className="row flex-md-row flex-column-reverse h-100 mx-0">
              <div className="col-md-7 position-relative indian-map" style={{ zIndex: 10 }}>
                <div >
                  {/* <AuthorityMap
                    setSelectedState={setActiveStateName}
                    allStates={allStates}
                    onSelectState={onSelectState}
                    onSelectCity={onSelectCity}

                    stateOnClick={stateOnClick}
                    activeState={activeState}
                    setactiveState={setactiveState}
                  /> */}
                  <ReactDatamaps
                        regionData={allStates}
                        mapLayout={{
                            hoverTitle: "Count",
                            noDataColor: "#f6cb54",
                            borderColor: "#132845",
                            hoverColor: "#132845",
                        }}
                        hoverComponent={({ value }) => {
                            return (
                                <>
                                    <p>{value.name}</p>
                                </>
                            );
                        }}
                        onClick={stateOnClick}
                        activeState={activeState}
                    />
                </div>
              </div>

              <div className="col-md-5 position-relative pb-md-0 pb-5 mb-md-0 mb-5 pt-md-0 pt-5">
                <div className="authority-banner-text mt-md-4">
                  <p className="text-capitalize">
                    <span> {headingData?.title?.split(' ')[0]} </span>
                    <strong>{headingData?.title?.split(' ')?.slice(1)?.join(" ")}</strong>
                    <p className="authority-banner-subtext mb-0">
                      {headingData?.subtitle}
                    </p>
                  </p>
                </div>

                <div className=" authority-slider">
                  <Slider {...sliderSettings} className="pb-4" >
                    {allStates?.map((prop) => {
                      return (
                        <div
                          className={` mb-md-0 mb-5 `}
                          key={prop.id}
                        >
                          <div
                            className={`slider-container ${activeStateName === prop.state_name ? 'active-container' : ''
                              }`}
                            onClick={() => stateOnClick({ value: prop.id }, prop.state_name)}
                          >
                            <div className={`slider-div ${activeStateName === prop.state_name ? 'active-slide' : ''
                              }`}>
                              <h5 className="fw-bold mb-2 text-uppercase">{prop.state_name}</h5>
                              <p className="mb-0 fw-600">{'Choose Your Preferred Tehsil'}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <section >
        <div className="section-authority mx-auto">
          <div style={{ position: 'relative', zIndex: 10 }}>
            <p>
              INDIA/<span style={{ fontWeight: "600" }}>{activeStateName}</span>/{" "}
              <span>All District</span>
            </p>
            <div>
              <h6>SEARCH DISTRICT BY STATE</h6>
              <div className="authority-searchbar d-flex align-items-center">
                <div className="authority-search-navbar" style={{ width: "100%" }}>
                  <form onSubmit={handleSearchState}>
                    <div
                      className="input-group main-searh-input "
                      style={{ width: "100%" }}
                    >
                      <input
                        type="text"
                        className="form-control search-district text-dark-blue fs-6"
                        placeholder="Name Of State"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={searchState}
                        onChange={(e) => setSearchState(e.target.value)}
                        style={{
                          height: "42px",
                          fontSize: "13px",
                          border: "transparent",
                          backgroundColor: "#FAF4E4",
                          color: "#132845",
                        }}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary "
                          type="submit"
                          // onClick={handleSearchState}
                          style={{
                            height: "42px",
                            paddingTop: "2px",
                            backgroundColor: "#FAF4E4",
                            color: "#132845",
                            border: "transparent",
                            borderLeft: "transparent",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px",
                          }}
                        >
                          <i className="fa fa-search fs-5 lh-sm"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div style={{ paddingTop: "0px", marginLeft: "10px" }}>
                  <div className="button" style={{ cursor: 'pointer' }} onClick={() => {
                    if (searchState)
                      window.location.reload()
                  }}>
                    <i className="bi bi-arrow-repeat lh-sm" style={{ fontSize: "22px" }}></i>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="authority-cards-section">
            <p>All Tahsil/District <span >in {activeStateName}</span></p>
            <div className="row justify-content-md-start justify-content-center">

              {allDistricts?.map((city) => {
                return (<div
                  key={city.id}
                  className={`col-md-3 col-sm-6 col-11 mt-4 ${activeDistrict?.id === city.id ? 'active-tahsil' : ''}`}
                  onClick={() => onSelectCity({ id: city.id, name: city.city_name })}
                >
                  <div className={`card ${activeDistrict?.id === city.id ? 'active-card' : ''}`}>
                    <h6 className="fw-bold text-uppercase">{city.city_name}</h6>
                    <p className="fw-600">{'Choose Your Preferred Tehsil'}</p>
                  </div>
                </div>)
              })}
              {
                allDistricts.length === 0 &&
                <div className="paragraph"> No Tahsil/District Found!</div>
              }
            </div>
          </div>
          <div className="authority-table-section">
            <p>All Tahsil <span>in {activeDistrict?.name}</span></p>

            <div>
              <table className="table tehsil-table col-md-5">
                <thead className="table-header">
                  <tr>
                    <th>DISTRICT</th>
                    <th>TEHSIL</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {allTehsils?.map((row, i) => (
                    <tr key={i}>
                      <td>{activeDistrict?.name}</td>
                      <td>{row.locality_name}</td>
                    </tr>
                  ))}
                  {
                    allTehsils.length === 0 &&
                    <tr >
                      <td colSpan={2} className="text-center">No Data found!</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default Authorities;
