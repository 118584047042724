import React, { useEffect, useState } from "react";
import CustomLoader from "../CustomLoader";
import "../../assets/Css/UserProfile.css"
import config, { axiosbaseurl } from "../../config";
import secureLocalStorage from "react-secure-storage";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "antd";

import userIcon from "../../assets/BuyDetailPage/user_icon.png"
import { customToastOptions } from "../../Styles/popup_style";

const UserProfile = () => {

    const [readLaterPost, setReadLaterPost] = useState([])

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("+91 ")
    const [profile_picture, setProfilePicture] = useState("")
    const [profile_picture_link, setProfilePictureLink] = useState(userIcon)

    const [currPassword, setCurrPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // State variables for errors
    const [error, setError] = useState('');
    const [error_profile, setErrorProfile] = useState('');

    const [loading, setLoading] = useState(false)
    const [logoutModal, setLogoutModal] = useState(false)
    const [deleteAccountModal, setDeleteAccount] = useState(false)

    const [showPwd, setShowPwd] = useState(false)

    const [notificationPreference, setNotificationPreference] = useState();

    const credentials = secureLocalStorage.getItem("credentials")

    const navigate = useNavigate();
    // console.log('credentials', credentials)

    // getting data like, news, blogs, name, phone, email..
    useEffect(() => {
        const controller = new AbortController();
        async function getSavedPosts() {
            setLoading(true)
            try {
                const { data } = await axiosbaseurl.post(`/savedBlogs`, { user_id: credentials?.user_id });

                if (data.success == true) {
                    setReadLaterPost(data)

                    setName(data?.user?.name)
                    setEmail(data?.user?.email)
                    setNotificationPreference(data?.user?.saved_notification);
                    if (data.user?.image)
                        setProfilePictureLink(data.user?.image)
                    handleChangePhone({ target: { value: String(data?.user?.contact) } })

                    secureLocalStorage.setItem("credentials", {...credentials, image: data.user?.image})
                }
                setLoading(false)

            } catch (error) {
                console.log(error)
            }
            finally {
                setLoading(false)
            }
        }
        getSavedPosts()

        if (!credentials) {
            navigate(config.appUrl)
        }

        return () => {
            controller.abort();
        };
    }, [])

    // for removing post
    const handleUnSavePost = async (post_id, type) => {
        setLoading(true)
        try {
            const postData = {
                user_id: credentials?.user_id,
                post_id: post_id,
                type: type
            }
            const res = await axiosbaseurl.post('/unsaveBlog', postData)

            setReadLaterPost(res.data)
            toast.success('Post Removed successfully!')
            setLoading(false)

        } catch (error) {
            console.log(error)
            toast.error('Something Went Wrong');
            setLoading(false)

        }
    }

    // validations for first form
    const handleValidation = () => {
        const newErrors = {};

        if (!name) {
            newErrors.name = "First Name is required!";
        } else if (/\d/.test(name)) {
            newErrors.name = "Digits not allowed in First Name";
        }

        if (!email) {
            newErrors.email = "Email is required";
        } else if (
            !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
        ) {
            newErrors.email = "Invalid email format";
        }
        if (phone.length === 4) {
            newErrors.phone = "Phone number is required";
        }
        else if (phone.length < 14 || phone.length > 14) {
            newErrors.phone = "Invalid phone number";
        }
        setErrorProfile(newErrors)
        return Object.keys(newErrors).length === 0;
    };
    // ===========================

    // for updating profile
    const handleSaveProfile = async (e, post_id, type) => {
        e.preventDefault()
        if (handleValidation()) {
            setLoading(true)
            try {
                // user_id: credentials?.user_id
                const postData = new FormData()

                postData.append('name', name)
                postData.append('phone', phone.slice(4))
                postData.append('email', email)
                postData.append('profile_photo', profile_picture)
                postData.append('user_id', credentials?.user_id)

                const res = await axiosbaseurl.post('/updateUser', postData)

                // console.log('profile', res)

                const updateCredentials = {
                    ...credentials,
                    name: res.data.data?.name,
                    email: res.data.data?.email,
                    image: res.data.data?.avatar,
                }

                // console.log(res)
                secureLocalStorage.setItem("credentials", updateCredentials)
                toast.success('Profile Updated successfully!')
                setProfilePictureLink(res.data.data?.avatar)
                setLoading(false)

            } catch (error) {
                console.log(error)
                toast.error('Something Went Wrong');
                setLoading(false)

            }
        }
    }

    const validatePasswords = () => {
        const errors = {};
    
        if (!currPassword) {
            errors.currPassword = "Current password is required!";
        }
    
        if (!newPassword) {
            errors.newPassword = "New password is required!";
        }
    
        if (!confirmPassword) {
            errors.confirmPassword = "Confirm password is required!";
        } else if (newPassword !== confirmPassword) {
            errors.confirmPassword = "Password & Confirm Password do not match.";
        }
    
        console.log(errors);
        setError(errors); // Set specific field errors
        return Object.keys(errors).length === 0;
    };
    

    // for reset password
    const handleResetPassword = async (e) => {
        e.preventDefault()
        if(validatePasswords()){
            setLoading(true)
            try {
                // user_id: credentials?.user_id
                const postData = new FormData()
                postData.append('current_password', currPassword)
                postData.append('new_password', newPassword)
                postData.append('user_id', credentials?.user_id)

                const res = await axiosbaseurl.post('/reset_password', postData)

                // console.log('res', res)
                if (res.data.success)
                    toast.success('Password Updated successfully!')
                else {
                    setError(res.data.data)
                    toast.error(res.data.data)
                }

                setLoading(false)

            } catch (error) {
                console.log(error)
                toast.error('Something Went Wrong');
                setLoading(false)

            }
        }

    }

    // for logout
    const handleLogout = () => {
        secureLocalStorage.removeItem("credentials");
        toast.success('Logout Successfull')
        setTimeout(() => {
            navigate(config.appUrl)
        }, 1000);
    }

    // for reset password
    const handleRemoveAccount = async () => {

        setLoading(true)
        try {
            // user_id: credentials?.user_id
            const postData = new FormData()
            postData.append('user_id', credentials?.user_id)

            const res = await axiosbaseurl.post('/deleteUser', postData)

            // console.log('res', res)
            if (res.data.success) {
                toast.success('Account Removed successfully!')
                setTimeout(() => {
                    handleLogout()
                }, 1500)
            }
            else {
                alert(JSON.stringify(res.data.data))
                toast.error(res.data.data)
            }

            setLoading(false)

        } catch (error) {
            console.log(error)
            toast.error('Something Went Wrong');
            setLoading(false)

        }

    }

    // for instant view profile photo
    const handleProfileImage = (e) => {
        setProfilePicture(e.target.files[0])
        // generating image link for instant view
        const reader = new FileReader();
        reader.onloadend = () => {
            setProfilePictureLink(reader.result);
        };
        if (e.target.files[0])
            reader.readAsDataURL(e.target.files[0]);
    }

    // for mobile number
    const handleChangePhone = (event) => {
        const value = event.target.value;
        // Check if the value starts with '+91' and remove any extra spaces
        if (value.startsWith('+91 ')) {
            setPhone(value);
        } else {
            // Ensure that '+91 ' is always at the beginning
            setPhone('+91 ' + value.replace(/^(\+91\s*)/, ''));
        }
    };

    const handleSave = async () => {
        if (!notificationPreference) {
            toast.error("Please select a notification preference.");
            return;
        }
        setLoading(true)
        try {
            // user_id: credentials?.user_id
            const postData = new FormData()
            postData.append('user_id', credentials?.user_id)
            postData.append('saved_notification',notificationPreference)

            const res = await axiosbaseurl.post('/user/saved-notification', postData)

            // console.log('res', res)
            if (res.data.success) {
                toast.success('Search Notifications Saved Successfully!');
            }
            else {
                alert(JSON.stringify(res.data.data))
                toast.error(res.data.data)
            }

            setLoading(false)

        } catch (error) {
            console.log(error)
            toast.error('Something Went Wrong');
            setLoading(false)

        }
        
    };

    return (
        <div>
            {loading && <CustomLoader />}
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />

            <div className='pt-90' style={{ backgroundColor: '#f4f4f4' }}>
                <div className="auto-container-lg py-5">
                    <div className="mb-4">
                        <span className="text-dark-blue fs-4 fw-500 "> Account</span>
                        <div className="yellow-line"></div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-md-6 pe-md-3">
                            <div className="account-container">
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Profile</span>
                                    <div className="yellow-line"></div>
                                </div>
                                <div className="profile_picture">
                                    <img src={profile_picture_link} alt="profile_picture" className="w-100 h-100" />
                                    <label htmlFor="profile_upload" className="upload_btn p-0 m-0">
                                        <i className="bi bi-camera-fill"></i>
                                    </label>
                                    <input type="file" onChange={handleProfileImage} className="d-none" id="profile_upload" />
                                </div>
                                <div>
                                    <form onSubmit={handleSaveProfile}>
                                        <div className="profile-form-field mt-2">
                                            <label htmlFor="">Name <span className="text-danger">*</span></label>
                                            <input type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="Enter here" />
                                            <div className="text-danger badge">{error_profile?.name}</div>
                                        </div>

                                        <div className="profile-form-field mt-2">
                                            <label htmlFor="">Mobile <span className="text-danger">*</span></label>
                                            <input type="text"
                                                value={phone}
                                                onChange={handleChangePhone}
                                                maxLength={14}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key))
                                                        event.preventDefault();
                                                }}
                                                placeholder="Enter here" />
                                            <div className="text-danger badge">{error_profile?.phone}</div>
                                        </div>

                                        <div className="profile-form-field mt-2">
                                            <label htmlFor="">Email <span className="text-danger">*</span></label>
                                            <input type="text"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter here" />
                                            <div className="text-danger badge">{error_profile?.email}</div>
                                        </div>

                                        <div className="mt-4">
                                            <button className="profile-btn">
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {/* change pwd */}
                            <div className="account-container">
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Change Password</span>
                                    <div className="yellow-line"></div>
                                </div>

                                {/* <div>
                                    {
                                        error &&
                                        <div className="alert alert-danger rounded-pill py-2 mt-4 mb-0 text-center">{error}</div>
                                    }
                                </div> */}

                                <div>
                                    <form onSubmit={handleResetPassword}>
                                        <div className="profile-form-field mt-2">
                                            <label htmlFor="">Current Password <span className="text-danger">*</span></label>
                                            <input type={showPwd ? "text" : "password"}
                                                value={currPassword}
                                                onChange={(e) => setCurrPassword(e.target.value)}
                                                placeholder="Enter here" />
                                            <div className="text-danger badge">{error?.currPassword}</div>
                                        </div>

                                        <div className="profile-form-field mt-2">
                                            <label htmlFor="">New Password <span className="text-danger">*</span></label>
                                            <input type={showPwd ? "text" : "password"}
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                placeholder="Enter here" />
                                            <div className="text-danger badge">{error?.newPassword}</div>
                                        </div>

                                        <div className="profile-form-field mt-2 mb-3">
                                            <label htmlFor="">Confirm Password <span className="text-danger">*</span></label>
                                            <input type={showPwd ? "text" : "password"}
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                placeholder="Enter here" />
                                            <div className="text-danger badge">{error?.confirmPassword}</div>
                                        </div>

                                        <div>
                                            <input type="checkbox" id="show" onChange={(e) => setShowPwd(e.target.checked)} />
                                            <label htmlFor="show" className="ms-2">Show Password</label>
                                        </div>

                                        <div className="mt-4">
                                            <button className="profile-btn">
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {/* subscriptions */}
                            <div className="account-container">
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Subscriptions</span>
                                    <div className="yellow-line"></div>
                                </div>

                                <p className="text-dark-blue my-4">
                                    Choose your preferred Subscriptions.
                                </p>

                                <div className="pt-2">
                                    <button className="profile-btn logout-btn">
                                        Select
                                    </button>
                                </div>
                            </div>

                            {/* saved blogs */}
                            <div className="account-container" >
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Saved Blogs</span>
                                    <div className="yellow-line"></div>
                                </div>
                                <div style={{ maxHeight: '400px', overflow: 'auto', scrollbarWidth: 'none' }}>
                                    {
                                        readLaterPost?.blogs?.map((post) => {
                                            return (
                                                <div key={post.id} className="row mt-4 read-later align-items-center">
                                                    <div className="col-md-3">
                                                        <img src={post.url} alt="postImage" className="w-100 rounded-3" />
                                                    </div>
                                                    <div className="col-md-8 mt-md-0 mt-2">
                                                        <div>
                                                            <h5 className="text-dark-blue">{post.post_title}</h5>
                                                            <div className="d-flex justify-content-between">
                                                                <Link to={`${config.appUrl}blog/${post.slug}`} className="text-yellow fw-500"> Read Now
                                                                    <i className="bi bi-chevron-double-right ms-2"></i></Link>

                                                                <button className="profile-btn px-2 bg-light text-danger py-1"
                                                                    onClick={() => handleUnSavePost(post.id, 1)}>
                                                                    <i className="bi bi-trash me-1"></i> Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 ps-md-3">
                            {/* My Propreneur */}
                            <div className="account-container">
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">My Propreneur</span>
                                    <div className="yellow-line"></div>
                                </div>

                                <p className="text-dark-blue mt-4 mb-1">
                                    You aren't connected any propreneur yet.
                                    <a href={`${config.appUrl}proprenuers`} className="text-yellow text-decoration-none"> Browse propreneur</a>
                                </p>
                            </div>

                            <div className="account-container">
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Default Saved Search Notifications </span>
                                    <div className="yellow-line"></div>
                                </div>

                                <p className="text-dark-blue mt-4">
                                    How often would you like to receive updates on future Saved Searches.
                                </p>

                                <div className="my-3">
                                    <div className="d-flex align-items-center">
                                        <input 
                                            type="radio" 
                                            className="custom-radio-btn" 
                                            id="immediate" 
                                            name="notify" 
                                            value={0} 
                                            checked={notificationPreference === 0}
                                            onChange={(e) => setNotificationPreference(e.target.value)} 
                                        />
                                        <label htmlFor="immediate" className="ms-3 mb-0 fw-500">Immediately</label>
                                    </div>
                                    <div className="d-flex align-items-center my-3">
                                        <input 
                                            type="radio" 
                                            className="custom-radio-btn" 
                                            id="daily" 
                                            name="notify" 
                                            value={1}
                                            checked={notificationPreference === 1} 
                                            onChange={(e) => setNotificationPreference(e.target.value)} 
                                        />
                                        <label htmlFor="daily" className="ms-3 mb-0 fw-500">Daily</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <input 
                                            type="radio" 
                                            className="custom-radio-btn" 
                                            id="never" 
                                            name="notify" 
                                            value={2} 
                                            checked={notificationPreference === 2}
                                            onChange={(e) => setNotificationPreference(e.target.value)} 
                                        />
                                        <label htmlFor="never" className="ms-3 mb-0 fw-500">Never</label>
                                    </div>
                                </div>

                                <div className="pt-2">
                                    <button className="profile-btn" onClick={handleSave}>
                                        Save
                                    </button>
                                </div>
                            </div>

                            {/* Delete Account */}
                            <div className="account-container">
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Delete Account</span>
                                    <div className="yellow-line"></div>
                                </div>

                                <p className="text-dark-blue my-4">
                                    Once you delete your Propusers Account, it cannot be reactivated.
                                </p>

                                <div className="pt-2">
                                    <button
                                        onClick={() => setDeleteAccount(true)}
                                        className="profile-btn logout-btn text-white" style={{ backgroundColor: '#F04D4D' }}>
                                        Delete your account
                                    </button>
                                </div>
                            </div>



                            {/* saved news */}
                            <div className="account-container" style={{ maxHeight: '400px', overflow: 'auto', scrollbarWidth: 'none' }}>
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Saved News</span>
                                    <div className="yellow-line"></div>
                                </div>
                                <div style={{ maxHeight: '400px', overflow: 'auto', scrollbarWidth: 'none' }}>
                                    {
                                        readLaterPost?.news?.map((post) => {
                                            return (
                                                <div key={post.id} className="row mt-4 read-later align-items-center">
                                                    <div className="col-md-3">
                                                        <img src={post.url} alt="postImage" className="w-100 rounded-3" />
                                                    </div>
                                                    <div className="col-md-8 mt-md-0 mt-2">
                                                        <div>
                                                            <h5 className="text-dark-blue">{post.post_title}</h5>
                                                            <div className="d-flex justify-content-between">
                                                                <Link to={`${config.appUrl}news/${post.slug}`} className="text-yellow fw-500"> Read Now
                                                                    <i className="bi bi-chevron-double-right ms-2"></i></Link>

                                                                <button className="profile-btn px-2 bg-light text-danger py-1"
                                                                    onClick={() => handleUnSavePost(post.id, 2)}>
                                                                    <i className="bi bi-trash me-1"></i> Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            {/* lOGOUT */}
                            <div className="account-container">
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Sign Out</span>
                                    <div className="yellow-line"></div>
                                </div>

                                <p className="text-dark-blue my-4">
                                    Signing out will log you out of your Propusers account.
                                </p>

                                <div className="pt-2">
                                    <button className="profile-btn logout-btn" onClick={() => setLogoutModal(true)}>
                                        Sign Out
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* logout popup */}
            <Modal
                open={logoutModal}
                title=""
                width={450}
                // className="career_page"
                centered
                onCancel={() => setLogoutModal(false)}
                footer={<div className="footer-none"></div>}
            >
                <div className="mt-md-5">
                    <div className="text-center">
                        <h2 className="fs-3 fw-bold">Are you sure?</h2>
                        <div className="pb-5 fs-6">You want to leave now?</div>

                        <button className="profile-btn me-3" onClick={handleLogout}>Logout now</button>
                        <button onClick={() => setLogoutModal(false)} className="profile-btn logout-btn ">
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>

            {/* delete account popup */}
            <Modal
                open={deleteAccountModal}
                title=""
                width={550}
                // className="career_page"
                centered
                onCancel={() => setDeleteAccount(false)}
                footer={<div className="footer-none"></div>}
            >
                <div className="mt-md-5">
                    <div className="text-center">
                        <h2 className="fs-3 fw-bold">Are you sure you want to delete your account?</h2>
                        <div className="pb-5 fs-6">
                            This action is irreversible and you will not be able to recover your account !</div>

                        <button className="profile-btn me-3" style={{ backgroundColor: '#F04D4D' }}
                            onClick={handleRemoveAccount} >Yes, Delete it</button>
                        <button onClick={() => setDeleteAccount(false)} className="profile-btn logout-btn ">
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default UserProfile